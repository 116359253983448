<template>
  <div>
    <!-- 発注入力（東北商品センター）画面 -->
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>発注入力（東北商品センター）</v-card-title>
            <v-card-subtitle
              >編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</v-card-subtitle
            >
            <v-card-text>
              <v-form ref="formOrder">
                <v-row>
                  <v-col cols="12" sm="9">
                    <v-row>
                      <!-- 注文番号 -->
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="currentParamsOrder.orderNo"
                          label="注文番号"
                          :hint="`保存時に自動採番`"
                          persistent-hint
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                      <!-- 部門名 -->
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="currentParamsOrder.departmentName"
                          label="部門名"
                          readonly
                          :hint="`初期設定値`"
                          persistent-hint
                        >
                        </v-text-field>
                      </v-col>
                      <!-- 発注番号 -->
                      <v-col cols="12" sm="4">
                        <v-text-field
                          v-model="currentParamsOrder.departmentOrderNo"
                          label="発注番号"
                          readonly
                          :hint="`初期設定値`"
                          persistent-hint
                        >
                        </v-text-field>
                      </v-col>
                    <!-- </v-row>
                    <v-row> -->
                      <!-- 作成日付 -->
                      <v-col cols="12" sm="8">
                        <v-menu
                          v-model="orderDateMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          :rules="[required]"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="currentParamsOrder.orderDate"
                              label="作成日付"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:append="orderDateMenu = !orderDateMenu"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentParamsOrder.orderDate"
                            @input="orderDateMenu = false"
                            :day-format="(date) => new Date(date).getDate()"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- 担当者コード -->
                      <v-col cols="12" sm="4">
                        <v-autocomplete
                          v-model="currentParamsOrder.managerCode"
                          label="担当者コード"
                          :items="managerList"
                          item-text="comboItem"
                          item-value="code"
                          :rules="[required]"
                          clearable
                          @change="updateManagerCombo"
                        ></v-autocomplete>
                      </v-col>
                      <!-- 仕入先カナ -->
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="currentParamsOrder.supplierCode"
                          label="仕入先カナ"
                          :items="supplierList"
                          item-text="comboItem"
                          item-value="code"
                          :rules="[required]"
                          clearable
                          autofocus
                          tabindex="1"
                        >
                        </v-autocomplete>
                      </v-col>
                      <!-- 仕入先名 -->
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="currentParamsOrder.supplierName"
                          label="仕入先名"
                          readonly
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-divider class="hidden-xm-and-down m-0 p-0" inset vertical></v-divider>
                  <v-col cols="12" sm="3" class="toshin-no-padding-margin">
                    <!-- トシンセンター用 - お客様コード -->
                    <v-col>
                      <v-text-field
                        v-model="currentParamsOrder.toshinClientNo"
                        label="トシンセンター用 - お客様コード"
                        counter="9"
                        :rules="[toshinNumber, limit_length9]"
                        :disabled="currentParamsOrder.toshinSection !== 1"
                        clearable
                        type="number"
                        hide-spin-buttons
                        tabindex="2"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <!-- トシンセンター用 - 受発注No -->
                      <v-text-field
                        v-model="currentParamsOrder.toshinOrderNo"
                        label="トシンセンター用 - 受発注No"
                        disabled                      
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <!-- トシンセンター用 - 発注者・注番等 -->
                      <v-text-field
                        v-model="currentParamsOrder.toshinManagerOrderNo"
                        label="トシンセンター用 - 発注者・注番等"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- 摘要欄 -->
                  <v-col cols="12" sm="9">
                    <v-textarea
                      v-model="currentParamsOrder.description"
                      label="摘要欄"
                      :hint="`※帳票出力可能行数  発注書:5行 トシン受発注票:4行`"
                      counter="255"
                      rows="1"
                      :rules="[limit_length255]"
                      clearable
                      auto-grow
                      tabindex="3"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-row class="justify-center mt-3">
                      <!-- 住所入力ボタン -->
                      <v-btn
                        color="secondary"
                        @click="onClickInputAddress"
                        rounded
                        auto-grow
                        tabindex="4"
                      >
                        <v-icon left> mdi-home-plus </v-icon>住所入力
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </v-form>
              <!-- 発注明細データエリア -->
              <v-form ref="formDetails">
                <v-data-table
                  dense
                  :headers="headers"
                  :items="orderDetailList"
                  :items-per-page="5"
                  class="elevation-5 mt-5 scroll-lock-order-input"
                  :item-class="rowClasses"
                  v-model="selected"
                  item-key="orderBranchNo"
                  show-select
                  draggable
                  disable-sort
                >
                  <template v-slot:top>
                    <v-form ref="formModal">
                      <v-toolbar flat>
                        <v-toolbar-title>発注明細</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <!-- 返品処理ダイアログ -->
                        <template v-if="selected == ''">
                          <v-btn
                            color="secondary"
                            dark
                            class="mb-2"
                            rounded
                            @click="checkItem()"
                            tabindex="5"
                          >
                            <v-icon left>mdi-clipboard-arrow-left</v-icon
                            >返品値引
                          </v-btn>
                        </template>
                        <template
                          v-else-if="treatedOrder"
                        >
                          <v-btn
                            color="secondary"
                            dark
                            class="mb-2"
                            rounded
                            @click="treatedItem()"
                            tabindex="5"
                          >
                            <v-icon left>mdi-clipboard-arrow-left</v-icon
                            >返品値引
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-dialog v-model="dialogReturn" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="secondary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                                rounded
                                tabindex="5"
                              >
                                <v-icon left>mdi-clipboard-arrow-left</v-icon
                                >返品値引
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">返品値引</span>
                              </v-card-title>
                              <v-card-subtitle>
                                <p>
                                  チェックした行の処理内容を選択してください。
                                </p>
                              </v-card-subtitle>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <!-- 処理内容 -->
                                    <v-col cols="12">
                                      <v-radio-group
                                        v-model="returnOrDiscount"
                                        label="処理内容"
                                        mandatory
                                        row
                                        hide-details="false"
                                      >
                                        <v-radio
                                          label="返品"
                                          value="0"
                                        ></v-radio>
                                        <v-radio
                                          label="値引"
                                          value="1"
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  dark
                                  color="secondary"
                                  rounded
                                  @click="closeReturn"
                                >
                                  キャンセル
                                </v-btn>
                                <v-btn
                                  dark
                                  color="secondary"
                                  rounded
                                  @click="saveReturn"
                                >
                                  OK
                                </v-btn>
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        <v-btn
                          class="mb-2 mx-2"
                          dark
                          color="secondary"
                          rounded
                          @click="addLine(5)"
                          tabindex="6"
                        >
                          <v-icon left>mdi-pen-plus</v-icon>行追加
                        </v-btn>
                        <v-btn
                          class="mb-2 mx-2"
                          dark
                          color="red accent-4"
                          rounded
                          @click="submitDeleteOrder"
                          tabindex="8"
                          v-if="currentParamsOrder.orderDataId"
                        >
                          <v-icon left>mdi-delete-sweep</v-icon>発注データ削除
                        </v-btn>
                        <!-- 追加編集ダイアログ -->
                        <v-dialog v-model="dialog" max-width="800px">
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">編集</span>
                              <v-divider class="mx-4 mb-1 mt-1" vertical></v-divider>
                              <span class="text-h5">明細No.{{ editOrderBranchNo }}</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.productCode"
                                      label="商品コード"
                                      disabled
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-row>
                                      <v-col cols="10">
                                        <v-combobox
                                          v-model="productObj"
                                          tabindex="20"
                                          label="品番"
                                          id="productNo"
                                          :items="productList"
                                          :loading="isLoadingProduct"
                                          :search-input.sync="searchProductNo"
                                          item-text="comboItem"
                                          item-value="productNo"
                                          :rules="[required, typeOfObj]"
                                          autofocus
                                          placeholder="3~10文字で入力"
                                          append-outer-icon="mdi-database-search"
                                          @change="changeProductNoList(editedItem, productObj)"
                                          @click:append-outer="
                                            (dialogProductSearch = !dialogProductSearch),
                                              (targetBranchNo = editedItem.orderBranchNo)
                                          "
                                        ></v-combobox>
                                      </v-col>
                                      <v-col cols="2" align-self="center" class="productNoIconLayout">
                                        <v-icon
                                          color="secondary"
                                          @click="dialogProductInsert = !dialogProductInsert, targetBranchNo = editedItem.orderBranchNo"
                                        >mdi-database-plus</v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.productName"
                                      tabindex="21"
                                      label="品名"
                                      clearable
                                      counter="40"
                                      :counter-value="productCount"
                                      :rules="[required, limit_lengthProductName]"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model.number="editedItem.sendaiDelivery"
                                      tabindex="22"
                                      :rules="[
                                        limit_length9,
                                        number,
                                      ]"
                                      counter="9"
                                      :counter-value="numCount"
                                      clearable
                                      label="仙台納品数"
                                      type="number"
                                      @input="updateSalesOfficeCombo(editedItem, DEPARTMENT_SENDAI)"
                                      hide-spin-buttons
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model.number="editedItem.sennanDelivery"
                                      tabindex="23"
                                      :rules="[
                                        limit_length9,
                                        number,
                                      ]"
                                      counter="9"
                                      :counter-value="numCount"
                                      clearable
                                      label="仙南納品数"
                                      type="number"
                                      @input="updateSalesOfficeCombo(editedItem, DEPARTMENT_SENNAN)"
                                      hide-spin-buttons
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model.number="editedItem.senbokuDelivery"
                                      tabindex="24"
                                      :rules="[
                                        limit_length9,
                                        number,
                                      ]"
                                      counter="9"
                                      :counter-value="numCount"
                                      clearable
                                      label="仙北納品数"
                                      type="number"
                                      @input="updateSalesOfficeCombo(editedItem, DEPARTMENT_SENBOKU)"
                                      hide-spin-buttons
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model.number="editedItem.amount"
                                      tabindex="25"
                                      :rules="[
                                        amountRequired,
                                        limit_length9,
                                        number,
                                      ]"
                                      counter="9"
                                      :counter-value="numCount"
                                      clearable
                                      label="数量"
                                      type="number"
                                      hide-spin-buttons
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-combobox
                                      v-model="editedItem.unit"
                                      tabindex="26"
                                      :items="unitList"
                                      :rules="[limit_length10]"
                                      counter="10"
                                      ref="unit"
                                      clearable
                                      label="単位"
                                    ></v-combobox>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model.number="editedItem.net"
                                      tabindex="27"
                                      :rules="[price]"
                                      label="NET"
                                      clearable
                                      counter="11"
                                      :counter-value="numCount"
                                      ref="moneyInput"
                                      @change="editedNet(editedItem.net)"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model.number="editedItem.lastCost"
                                      tabindex="28"
                                      :rules="[price]"
                                      counter="11"
                                      :counter-value="numCount"
                                      clearable
                                      label="最終仕入価格"
                                      ref="moneyInput"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.salesOfficeCode"
                                      label="営業部門コード"
                                      disabled
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete
                                      v-model="editedItem.salesOfficeName"
                                      tabindex="29"
                                      id="salesOfficeName"
                                      :items="salesOfficeNameList"
                                      item-text="comboItem"
                                      item-value="name"
                                      label="営業所名"
                                      :rules="[required]"
                                      @change="changeSalesOfficeNameList(editedItem)"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                      v-model="editedItem.detailsDescription"
                                      tabindex="30"
                                      label="備考"
                                      clearable
                                      :rules="[limit_length50]"
                                      counter="50"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                tabindex="31"
                                dark
                                color="secondary"
                                rounded
                                @click="close"
                              >
                                キャンセル
                              </v-btn>
                              <v-btn
                                tabindex="32"
                                dark
                                color="secondary"
                                rounded
                                @click="saveClose"
                              >
                                OK
                              </v-btn>
                              <v-btn
                                tabindex="33"
                                dark
                                color="secondary"
                                rounded
                                @click="saveAddLine"
                              >
                                OK（続けて入力）
                              </v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <!-- 商品検索モーダル -->
                        <template v-if="dialogProductSearch">
                          <v-dialog
                            v-model="dialogProductSearch"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                          >
                            <v-card>
                              <v-toolbar dark color="primary">
                                <v-btn
                                  icon
                                  dark
                                  @click="response(null)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-toolbar>
                              <v-row>
                                <v-col>
                                  <!-- 商品検索エリア（モーダル共通） -->
                                  <ProductSearchArea
                                    :transitionFlg="true"
                                    :targetBranchNo="targetBranchNo"
                                    @result="response"
                                  ></ProductSearchArea>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-dialog>
                        </template>
                        <!-- 商品登録モーダル -->
                        <template v-if="dialogProductInsert">
                          <v-dialog
                            v-model="dialogProductInsert"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                          >
                            <v-card>
                              <v-toolbar dark color="primary">
                                <v-btn
                                  icon
                                  dark
                                  @click="response(null)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-toolbar>
                              <v-row>
                                <v-col>
                                  <!-- 商品登録エリア（モーダル共通） -->
                                  <ProductInsertArea
                                    :transitionFlg="true"
                                    :targetBranchNo="targetBranchNo"
                                    :insertProductNo="insertProductNo"
                                    @result="response"
                                  ></ProductInsertArea>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-dialog>
                        </template>
                      </v-toolbar>
                    </v-form>
                  </template>
                  <!-- No編集ダイアログ -->
                  <!-- 品番 -->
                  <template v-slot:[`item.productNo`]="{ item }">
                    <v-edit-dialog
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @open="openProductNo(item)"
                      @save="changeProductNoList(item, productObj)"
                      @cancel="cancel"
                    >
                      <v-chip link>{{ item.productNo }}</v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">品番</div>
                        <v-row>
                          <v-col cols="10">
                            <v-combobox
                                v-model="productObj"
                                id="productNo"
                                :items="productList"
                                :loading="isLoadingProduct"
                                :search-input.sync="searchProductNo"
                                item-text="comboItem"
                                autofocus
                                placeholder="3~10文字で入力"
                                append-outer-icon="mdi-database-search"
                                @click:append-outer="
                                  (dialogProductSearch = !dialogProductSearch),
                                    (targetBranchNo = item.orderBranchNo)
                                "
                            ></v-combobox>
                          </v-col>
                          <v-col cols="2" align-self="center" class="productNoIconLayout">
                            <v-icon
                              color="secondary"
                              @click="dialogProductInsert = !dialogProductInsert,
                                      targetBranchNo = item.orderBranchNo
                                      insertProductNo = searchProductNo"
                            >mdi-database-plus</v-icon>
                          </v-col>
                        </v-row>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 品名 -->
                  <template v-slot:[`item.productName`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.productName"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="saveDetails"
                      @cancel="cancel"
                    >
                      <v-chip link>{{ item.productName }}</v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">品名</div>
                        <v-text-field
                          v-model="item.productName"
                          id="productName"
                          :rules="[required, limit_lengthProductName]"
                          single-line
                          counter="40"
                          :counter-value="productCount"
                          clearable
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 数量 -->
                  <template v-slot:[`item.amount`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.amount"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="saveDetails"
                      @cancel="cancel"
                    >
                      <v-chip link>
                        {{ item.amount }}
                      </v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">数量</div>
                        <v-text-field
                          v-model.number="item.amount"
                          id="amount"
                          :rules="[amountRequired, limit_length9, number]"
                          single-line
                          counter="9"
                          :counter-value="numCount"
                          clearable
                          autofocus
                          type="number"
                          hide-spin-buttons
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 単位 -->
                  <template v-slot:[`item.unit`]="{ item }">
                    <v-edit-dialog
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @open="openUnit(item)"
                      @save="saveUnit(item)"
                      @cancel="cancel"
                    >
                      <v-chip link>{{ item.unit }}</v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">単位</div>
                        <v-combobox
                          v-model="viewUnit"
                          id="unit"
                          :items="unitList"
                          :rules="[limit_length10]"
                          counter="10"
                          clearable
                          autofocus
                          hide-selected
                          ref="unitComboBox"
                        >
                        </v-combobox>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 在庫数 -->
                  <template v-slot:[`item.sennanAmount`]="{ item }">
                    <v-chip :color="getColor(item.sennanLastDate)">
                      {{ item.sennanAmount }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.sendaiAmount`]="{ item }">
                    <v-chip :color="getColor(item.sendaiLastDate)">
                      {{ item.sendaiAmount }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.senbokuAmount`]="{ item }">
                    <v-chip :color="getColor(item.senbokuLastDate)">
                      {{ item.senbokuAmount }}
                    </v-chip>
                  </template>
                  <!-- NET -->
                  <template v-slot:[`item.net`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.net"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="callUpdateNet"
                      @cancel="cancel"
                    >
                      <v-chip link>
                        <div v-if="item.net==null || item.net==``">{{item.net}}</div>
                        <div v-else>{{ new Number(item.net).toLocaleString() }}</div></v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">NET</div>
                        <v-text-field
                          v-model.number="item.net"
                          id="net"
                          :rules="[price]"
                          single-line
                          counter="11"
                          :counter-value="numCount"
                          autofocus
                          clearable
                          ref="moneyInput"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 最終仕入価格 -->
                  <template v-slot:[`item.lastCost`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.lastCost"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="saveDetails"
                      @cancel="cancel"
                    >
                      <v-chip link>
                        <div v-if="item.lastCost==null || item.lastCost==``">{{item.lastCost}}</div>
                        <div v-else>{{ new Number(item.lastCost).toLocaleString() }}</div></v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">最終仕入価格</div>
                        <v-text-field
                          v-model.number="item.lastCost"
                          id="lastCost"
                          :rules="[price]"
                          single-line
                          counter="11"
                          :counter-value="numCount"
                          autofocus
                          clearable
                          ref="moneyInput"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 営業所名 -->
                  <template v-slot:[`item.salesOfficeName`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.salesOfficeName"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="changeSalesOfficeNameList(item)"
                      @cancel="cancel"
                    >
                      <v-chip link>{{ item.salesOfficeName }}</v-chip>
                        <template v-slot:input>
                        <div class="mt-4 text-h6">営業所名</div>
                      <v-autocomplete
                        v-model="item.salesOfficeName"
                        id="salesOfficeName"
                        :items="salesOfficeNameList"
                        item-text="comboItem"
                        item-value="name"
                        autofocus
                      ></v-autocomplete>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 受注営業所 -->
                  <template v-slot:[`item.ordersSalesOfficeName`]="{ item }">
                    <span>{{ salesOffice(item) }}</span>
                  </template>
                  <!-- 仙台納品数 -->
                  <template v-slot:[`item.sendaiDelivery`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.sendaiDelivery"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="updateSalesOfficeCombo(item, DEPARTMENT_SENDAI)"
                      @cancel="cancel"
                    >
                      <v-chip link>
                        {{ item.sendaiDelivery }}
                      </v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">仙台納品数</div>
                        <v-text-field
                          v-model.number="item.sendaiDelivery"
                          id="sendaiAmount"
                          :rules="[limit_length9, number]"
                          single-line
                          counter="9"
                          :counter-value="numCount"
                          clearable
                          autofocus
                          type="number"
                          hide-spin-buttons
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 仙南納品数 -->
                  <template v-slot:[`item.sennanDelivery`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.sennanDelivery"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="updateSalesOfficeCombo(item, DEPARTMENT_SENNAN)"
                      @cancel="cancel"
                    >
                      <v-chip link>
                        {{ item.sennanDelivery }}
                      </v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">仙南納品数</div>
                        <v-text-field
                          v-model.number="item.sennanDelivery"
                          id="sennanAmount"
                          :rules="[limit_length9, number]"
                          single-line
                          counter="9"
                          :counter-value="numCount"
                          clearable
                          autofocus
                          type="number"
                          hide-spin-buttons
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 仙北納品数 -->
                  <template v-slot:[`item.senbokuDelivery`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.senbokuDelivery"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="updateSalesOfficeCombo(item, DEPARTMENT_SENBOKU)"
                      @cancel="cancel"
                    >
                      <v-chip link>
                        {{ item.senbokuDelivery }}
                      </v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">仙北納品数</div>
                        <v-text-field
                          v-model.number="item.senbokuDelivery"
                          id="senbokuAmount"
                          :rules="[limit_length9, number]"
                          single-line
                          counter="9"
                          :counter-value="numCount"
                          clearable
                          autofocus
                          type="number"
                          hide-spin-buttons
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 備考 -->
                  <template v-slot:[`item.detailsDescription`]="{ item }">
                    <v-edit-dialog
                      :return-value.sync="item.detailsDescription"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="saveDetails"
                      @cancel="cancel"
                    >
                      <v-chip link>{{ item.detailsDescription }}</v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">備考</div>
                        <v-text-field
                          v-model="item.detailsDescription"
                          id="detailsDescription"
                          :rules="[limit_length50]"
                          single-line
                          counter="50"
                          clearable
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <!-- 仙台入荷 -->
                  <template v-slot:[`item.sendaiInFlg`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.sendaiInFlg"
                      color="secondary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </template>
                  <!-- 仙南入荷 -->
                  <template v-slot:[`item.sennanInFlg`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.sennanInFlg"
                      color="secondary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </template>
                  <!-- 仙北入荷 -->
                  <template v-slot:[`item.senbokuInFlg`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.senbokuInFlg"
                      color="secondary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </template>
                  <!-- 仮伝 -->
                  <template v-slot:[`item.tempSlipFlg`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.tempSlipFlg"
                      color="secondary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </template>
                  <!-- 本伝 -->
                  <template v-slot:[`item.slipFlg`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.slipFlg"
                      color="secondary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </template>
                  <!-- 返品 -->
                  <template v-slot:[`item.returnFlg`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.returnFlg"
                      color="secondary"
                      :ripple="false"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  <!-- 値引 -->
                  <template v-slot:[`item.discountFlg`]="{ item }">
                    <v-simple-checkbox
                      v-model="item.discountFlg"
                      color="secondary"
                      :ripple="false"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  <!-- 良品/不良品 -->
                  <template v-slot:[`item.returnStatus`]="{ item }">
                    <template v-if="item.returnFlg == true">
                      <v-radio-group
                        v-model="item.returnStatus"
                        label=""
                        row
                        dense
                      >
                        <v-radio
                          v-for="returnStatus in returnStatusList"
                          :key="returnStatus.id"
                          :label="returnStatus.name"
                          :value="returnStatus.id"
                        ></v-radio>
                      </v-radio-group>
                    </template>
                    <template v-else>
                      <v-radio-group
                        v-model="item.returnStatus"
                        label=""
                        row
                        dense
                        disabled
                      >
                        <v-radio
                          v-for="returnStatus in returnStatusList"
                          :key="returnStatus.id"
                          :label="returnStatus.name"
                          :value="returnStatus.id"
                        ></v-radio>
                      </v-radio-group>
                    </template>
                  </template>
                  <!-- 操作 -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-container>
                      <v-row>
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="m-0"
                              fab
                              dark
                              x-small
                              color="secondary"
                              v-bind="attrs"
                              v-on="on"
                              @click="onClickEditItem(item)"
                              tabindex="7"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn></template
                          >
                          <span>修正</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-1"
                              fab
                              dark
                              x-small
                              color="secondary"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteItem(item)"
                              tabindex="8"
                              ><v-icon>mdi-delete</v-icon>
                            </v-btn></template
                          >
                          <span>削除</span>
                        </v-tooltip>
                      </v-row>
                    </v-container>
                  </template>
                </v-data-table>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <!-- 保存ボタン -->
              <v-btn
                color="secondary"
                rounded
                @click="onClickRegister"
                tabindex="9"
                >
                <v-icon left> mdi-content-save </v-icon>保存
              </v-btn>
              <!-- 新規作成ボタン -->
              <v-btn
                color="secondary"
                rounded
                @click="onClickClearOrder"
                tabindex="10"
              >
                <v-icon left> mdi-text-box-plus </v-icon>新規入力
              </v-btn>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <v-row justify="center">
                <!-- 印刷フォーマット -->
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="copyFormat"
                    :items="copyFormatList"
                    item-text="text"
                    item-value="value"
                    label="印刷フォーマット"
                    persistent-hint
                    hide-details="false"
                    return-object
                    tabindex="11"
                  ></v-select>
                </v-col>
                <!-- PDF出力ボタン -->
                <v-col cols="12" sm="2" class="d-md-flex align-center">
                  <v-btn
                    color="secondary"
                    @click="onClickPdfPrint"
                    rounded
                    tabindex="12"
                  >
                    <v-icon left> mdi-file-pdf-box </v-icon>PDF出力
                  </v-btn>
                </v-col>
              </v-row>
              <v-dialog v-model="dialogDataNotExists" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">確認</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      出力対象のデータが存在しませんでした
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="secondary" rounded @click="dialogDataNotExists = false"> OK </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ProductSearchArea from "../components/ProductSearchArea.vue";
import ProductInsertArea from "../components/ProductInsertArea.vue";
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import {
  listM_TANTOSHA,
  listM_SHIIRESAKI,
  getM_SHOKISETTEI,
  getM_SHIIRESAKI,
  getT_CHOHYO_COUNTER,
  getT_CENTER_HACCHU_DATA,
  listT_CENTER_HACCHU_MEISAI_DATA,
  listV_CENTER_HACCHU_DATA_WITH_MEISAI,
} from "@/graphql/queries";
import { executeTransactSql, executeHanbaikanriSql } from "@/graphql/mutations";
import { DEPARTMENT_SENDAI, DEPARTMENT_SENNAN, DEPARTMENT_SENBOKU, RETURN_PRODUCT_STATUS } from "@/assets/js/const";
import { CONFIRM_MESSAGE_UNSAVED_DATA, CONFIRM_MESSAGE_SAVE_DATA, CONFIRM_MESSAGE_DELETE_DATA, ALERT_MESSAGE_COMMON_ERROR, ALERT_SAVE_ZERO_DATA, ALERT_COMPLETE_CRUD_DATA, ALERT_ALREADY_SAVED_DATA, ALERT_UNSELECTED_DATA, ALERT_COMPLETE_INSERT_DATA, ALERT_UNSAVED_DATA, ALERT_DELETE_NOT_ALLOWED, ALERT_COMPLETE_DELETE_ORDER, CONFIRM_MESSAGE_DELETE_ORDER } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import { base64Encode } from '@/assets/js/common';
export default {
  name: "CenterOrderInput",

  components: {
    ProductSearchArea,
    ProductInsertArea,
  },
  props: {
    // 商品検索・商品登録モーダル返却値 - 商品コード
    returnProductCode: Number,
    // モーダルでの呼び出し状態
    transitionFlg: Boolean,
  },

  data: () => ({
    // 変数定義
    // 画面上の設定値（発注データ）
    currentParamsOrder: {
      // 発注データID
      orderDataId: null,
      // 部門コード
      departmentCode: null,
      // パナソニック区分
      panasonicSection: null,
      // 注文番号
      orderNo: null,
      // 注文日付
      orderDate: null,
      // 仕入先コード
      supplierCode: null,
      // 仕入先名
      supplierName: null,
      // 手数料区分
      commissionSection: null,
      // トシン経由区分
      toshinSection: null,
      // トシンお客様コード
      toshinClientNo: null,
      // トシン受発注番号
      toshinOrderNo: null,
      // トシン発注者注文番号
      toshinManagerOrderNo: null,
      // 担当者コード
      managerCode: null,
      // 担当者名
      managerName: null,
      // 摘要
      description: null,
      // 作成日付
      createdDate: null,
      // 作成者名
      createdUser: null,
      // 更新日付
      updatedDate: null,
      // 更新者名
      updatedUser: null,

      // 以下、画面でのみ使用
      // 部門名
      departmentName: null,
      // 発注番号
      departmentOrderNo: null,
    },

    // 未保存チェック用
    originalParamsOrder: {},
    originalParamsDetails: [],

    // ログインユーザー情報
    loginUserInfo: null,
    // ログインユーザー所属部門情報
    departmentInfo: null,

    // 作成日付表示用フラグ
    orderDateMenu: false,

    // 仕入先リスト（コード、名前）
    supplierList: [],

    // 担当者リスト（コード、名前）
    managerList: [],

    // 印刷フォーマット
    copyFormat: { value: "3", text: "発注書（東北商品センター用）" },
    // 印刷フォーマットリスト
    copyFormatList: [
      { value: "3", text: "発注書（東北商品センター用）" },
      { value: "4", text: "トシン受発注票" },
      { value: "6", text: "返品依頼書" },
      { value: "7", text: "返品伝票" },
    ],

    // 発注明細データ
    tableData: [],

    // 明細用データ
    // チェックボックス
    selected: [],
    // 追加編集ダイアログ
    dialog: false,
    // 削除ダイアログ
    dialogDelete: false,
    // 返品処理ダイアログ
    dialogReturn: false,
    // 商品検索ダイアログ
    dialogProductSearch: false,
    // 商品登録ダイアログ
    dialogProductInsert: false,
    // 担当者検索ダイアログ
    dialogManagerSearch: false,
    // データ非存在ダイアログ
    dialogDataNotExists: false,
    // 商品検索・商品登録モーダルに渡す明細NO
    targetBranchNo: 0,
    // 商品登録モーダルに渡す品番
    insertProductNo: null,
    // Datatablesのヘッダー
    headers: [
      { text: "", value: "data-table-select", width: "50px", align:"center" },
      { text: "操作", value: "actions", width: "80px" },
      { text: "明細No", value: "orderBranchNo", width: "40px" },
      { text: "商品コード", value: "productCode", width: "100px" },
      { text: "品番", value: "productNo", width: "100px" },
      { text: "品名", value: "productName", width: "150px" },
      { text: "数量", value: "amount", width: "50px" },
      { text: "単位", value: "unit", width: "50px" },
      { text: "仙南", value: "sennanAmount", width: "40px" },
      { text: "仙台", value: "sendaiAmount", width: "40px" },
      { text: "仙北", value: "senbokuAmount", width: "40px" },
      { text: "NET", value: "net", width: "20px" },
      { text: "最終仕入価格", value: "lastCost", width: "60px" },
      { text: "納品数(仙台)", value: "sendaiDelivery", width: "50px" },
      { text: "納品数(仙南)", value: "sennanDelivery", width: "50px" },
      { text: "納品数(仙北)", value: "senbokuDelivery", width: "50px" },
      { text: "営業部門コード", value: "salesOfficeCode", width: "110px" },
      { text: "営業所名", value: "salesOfficeName", width: "150px" },
      { text: "受注営業所", value: "ordersSalesOfficeName", width: "150px" },
      { text: "備考", value: "detailsDescription", width: "200px" },
      { text: "入荷(仙台)", value: "sendaiInFlg", width: "50px" },
      { text: "入荷(仙南)", value: "sennanInFlg", width: "50px" },
      { text: "入荷(仙北)", value: "senbokuInFlg", width: "50px" },
      { text: "仮伝", value: "tempSlipFlg", width: "40px" },
      { text: "本伝", value: "slipFlg", width: "40px" },
      { text: "返品", value: "returnFlg", width: "40px" },
      { text: "値引", value: "discountFlg", width: "40px" },
      { text: "良品/不良品", value: "returnStatus", width: "210px" },
    ],

    // テーブルに格納するデータの初期値
    orderDetailList: [],
    // 営業部門コードリスト
    salesOfficeNameList: [],
    // 単位のコンボボックスを追加
    unitList: ["個", "組", "箱", "本", "セット", "台", "巻", "m", "面"],
    unit: "個",
    viewUnit: null,

    // 修正画面表示用フラグ
    reqUpdate: false,
    // 編集インデックス
    editedIndex: -1,
    // 追加編集データ
    editedItem: {},
    // 数量編集
    editedAmount: -1,
    // 明細データの初期値
    defaultItem: {
      // 発注データID
      orderDataId: null,
      // 明細No
      orderBranchNo: null,
      // 部門コード
      departmentCode: null,
      // パナソニック区分
      panasonicSection: null,
      // 注文番号
      orderNo: null,
      // 注文日付
      orderDate: null,
      // 商品コード
      productCode: null,
      // 品番
      productNo: null,
      // 品名
      productName: null,
      // 数量
      amount: 0,
      // NET
      net: 0,
      // 最終仕入価格
      lastCost: 0,
      // 営業所名
      salesOfficeName: null,
      // 出荷日
      outDate: null,
      // 着日
      inDate: null,
      // 仙台納品数
      sendaiDelivery: 0,
      // 仙南納品数
      sennanDelivery: 0,
      // 仙北納品数
      senbokuDelivery: 0,
      // 受注営業所
      ordersSalesOfficeName: null,
      // 備考
      detailsDescription: null,
      // 返伝NO
      returnSlipNo: null,
      // 卸価格原価
      wholesaleCost: null,
      // 仙台入荷フラグ
      sendaiInFlg: false,
      // 仙南入荷フラグ
      sennanInFlg: false,
      // 仙北入荷フラグ
      senbokuInFlg: false,
      // 仮伝フラグ
      tempSlipFlg: false,
      // 本伝フラグ
      slipFlg: false,
      // 納品書日付
      deliveryDate: null,
      // 単位
      unit: "個",
      // 定価
      listPrice: null,
      // 営業部門コード
      salesOfficeCode: null,
      // 仮伝印刷フラグ
      tempSlipPrintFlg: false,
      // 仮伝日付
      tempSlipPrintDate: null,
      // JANコード
      productJanCode: null,
      // 入荷時仕入価格
      arrivalPurchasePrice: null,
      // 仕入先伝票番号
      purchaseSlipNo: null,
      // トシン伝票日付
      toshinSplitDate: null,
      // トシン伝票NO
      toshinSplitNo: null,
      // トシン伝票印刷フラグ
      toshinSplitPrintFlg: false,
      // トシン伝票印刷日付
      toshinSplitPrintDate: null,
      // 仕入CSVフラグ
      purchaseCsvFlg: false,
      // 仕入CSV出力日付
      purchaseCsvOutputDate: null,
      // キャンセルフラグ
      cancelFlg: false,
      // 返品ステータス
      returnStatus: 0,
      // 伝票区分
      splitSection: 1,
      // 引上フラグ
      pullUpFlg: false,
      // 依頼フラグ
      returnRequestFlg: false,
      // 返却フラグ
      returnProductFlg: false,
      // 仕入赤伝フラグ
      purchaseAkaCsvOutputFlg: false,
      // 仕入赤伝日付
      purchaseAkaCsvOutputDate: null,
      // 移動先部門コード
      destinationDepartmentCode: 0,
      // 作成日付
      createdDate: null,
      // 作成者名
      createdUser: null,
      // 更新日付
      updatedDate: null,
      // 更新者名
      updatedUser: null,

      // 以下、画面でのみ使用
      // 仙南
      sennanAmount: 0,
      // 仙台
      sendaiAmount: 0,
      // 仙北
      senbokuAmount: 0,
      // 返品フラグ
      returnFlg: false,
      // 値引フラグ
      discountFlg: false,
    },
    // 処理内容
    returnOrDiscount: null,

    // データテーブル内ダイアログでのカレンダー入力
    menuInDate: false,

    // 商品コード検索用
    productList: [],
    // ラジオボタン初期設定
    returnStatusList: RETURN_PRODUCT_STATUS,

    // 検索用テキストボックス無効化フラグ
    isDisableText: false,

    // API検索用オートコンプリート
    isLoadingProduct: false,
    searchProductNo: null,
    productObj: null,

    DEPARTMENT_SENBOKU: DEPARTMENT_SENBOKU,
    DEPARTMENT_SENNAN: DEPARTMENT_SENNAN,
    DEPARTMENT_SENDAI: DEPARTMENT_SENDAI,
    // 編集モーダル用明細No
    editOrderBranchNo: null,
    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    typeOfObj: (value) => typeof value == "object" || "プルダウンから選択してください",
    amountRequired: (value) =>
      (!!`${value}` && value !== null) || "必ず入力してください",
    // 文字数の制約 最大9文字
    limit_length9: (value) =>
      value == null || `${value}`.length <= 9 || "9桁以内で入力してください",
    // 文字数の制約 最大10文字
    limit_length10: (value) =>
      value == null || value.length <= 10 || "10文字以内で入力してください",
    // 文字数の制約 最大13文字
    limit_length13: (value) =>
      value == null || value.length <= 13 || "13桁以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value == null || value.length <= 20 || "20文字以内で入力してください",
    // 文字数の制約 最大50文字
    limit_length50: (value) =>
      value == null || value.length <= 50 || "50文字以内で入力してください",
    // 文字数の制約 最大80文字
    limit_length255: (value) =>
      value == null || value.length <= 255 || "255文字以内で入力してください",
    // 文字数の制約 半角40文字、全角20文字
    limit_lengthProductName: value => {
      let len = 0;
      if (value !== null) {
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < value.length; i++) {
          (value[i].match(/[ -~]/)) || (value[i].match(/[ｦ-ﾟ]/)) ? (len += 1) : (len += 2);
        }
      }
      return len <= 40 || `半角40文字、全角20文字以内で入力してください`
    },
    // お客様コードの制約
    toshinNumber: (value) =>
      value == null || /^[0-9,^e+.]+$/.test(value) || "整数で入力してください",
    // 数値の制約
    number: (value) =>
      value == null || /^[0-9,.-]+$/.test(value) || "数値で入力してください",
    // 価格の制約
    price: (value) =>
      value == null ||
      /^([-+]?[1-9][0-9]{0,7}|0)(\.[0-9]{1,2})?$/.test(
        String(value).replace(/,/g, "").trim()
      ) ||
      "整数値8桁、小数値2桁で入力してください",
    // 数値系のカウント
    numCount: (v) => {
      if (v !== null) {
        return `${v}`.replace(/-/g, "").trim().length;
      } else {
        return 0;
      }
    },
    // 商品項目（sjis）のカウント
    productCount: (v) => {
      if (v !== null) {
        let len = 0;
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < v.length; i++) {
          (v[i].match(/[ -~]/)) || (v[i].match(/[ｦ-ﾟ]/)) ? (len += 1) : (len += 2);
        }
        return len;
      } else {
        return 0;
      }
    },
  }),
  /**
   * 特定のデータや算出プロパティの状態の変化を監視
   */
  watch: {
    // 追加編集ダイアログ監視
    dialog(val) {
      val || this.close();
    },
    // 削除ダイアログ監視
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // 返品ダイアログ監視
    dialogReturn(val) {
      val || this.closeReturn();
    },
    /**
     * 品番のコンボボックス作成
     */
    async searchProductNo(val) {
      console.log("searchProductNo[in]：val = " + val);
      // 入力値がNULL、3文字以内、オートコンプリートの選択値（10文字以上）だった場合
      if (val == null || val.length < 3 || val.length > 10) {
        return;
      }
      // ローディング中は検索しない
      if (this.isLoadingProduct) {
        return;
      }

      // オートコンプリートをローディング中にする
      this.isLoadingProduct = true
      console.log("リスト初期化 2");
      // 品番リストを初期化
      this.productList = [];
      let sqlList = [
        `SELECT SH_SHOCD, SH_SHONM, SH_SHOKNM, SH_SIRTNA, SH_JAN FROM SHOHIN` +
        ` WHERE SH_SHOKNM LIKE '${val}%' `+
        ` AND ((SH_SBUBCD >= 40000 AND SH_SBUBCD < 60000) OR (SH_SBUBCD >= 10000 AND SH_SBUBCD < 20000))` +
        ` AND SH_SHOKNM IS NOT NULL AND SH_HAINOKB = 9` +
        ` ORDER BY SH_SHOKNM`
      ];
      let resultProductData = await this.executeHanbaikanriSql(sqlList);
      if (resultProductData == null || resultProductData == void 0) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // オートコンプリートのローディングを解除
        this.isLoadingProduct = false;
        return;
      }
      console.log("get resultProductData = " + resultProductData[0].length)
      for (const data of resultProductData[0]) {
        this.productList.push({
          comboItem: data.SH_SHOCD + "：" + data.SH_SHOKNM,
          code: data.SH_SHOCD,
          name: data.SH_SHONM,
          productNo: data.SH_SHOKNM,
          arrivalPurchasePrice: data.SH_SIRTNA,
          janCode: data.SH_JAN,
        });
      }
      // オートコンプリートのローディング中を解除する
      this.isLoadingProduct = false;
    },
    /**
     * 仕入先カナのコンボボックス作成
     */
    async supplierCode(val) {
      // メソッド名を定義
      const method_name="supplierCode"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (this.currentParamsOrder.supplierCode !== null) {
        // 仕入先名、パナソニック区分、印刷フォーマットを設定
        let selectedSuppiler = this.supplierList.find(
          (v) => v.code == val
        );

        this.currentParamsOrder.supplierName = selectedSuppiler.name;
        this.currentParamsOrder.panasonicSection =
          selectedSuppiler.panasonicSection;
        this.currentParamsOrder.commissionSection =
          selectedSuppiler.commissionSection;
      } else {
        this.currentParamsOrder.supplierName = null;
        this.currentParamsOrder.panasonicSection = null;
        this.currentParamsOrder.commissionSection = null;
      }

      // トシン経由区分が立っている場合、トシン系項目を設定
      this.updateToshinItems();

      // NETの計算方法を変更
      if (!(await this.beforeUpdateNet())) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     * 仕入先の発注書区分に応じて印刷フォーマットリストを変更
     */
    'currentParamsOrder.supplierCode': function(val) {
      const supplier = this.supplierList.find(
        (v) => v.code == val
      );
      if (supplier) {
        let purchaseOrderClass = this.copyFormatList.find(
          (v) => v.value == supplier.purchaseOrderClass
        );
        this.copyFormat = this.copyFormat = purchaseOrderClass;
      }
    },
  },
  /**
   * computed 算出プロパティ
   */
  computed: {
    // 受注営業所
    salesOffice() {
      return function (value) {
        let name = null;
        if (value.sendaiDelivery) {
          name = (name == null) ? "仙台　" : (name + "仙台　");
        }
        if (value.sennanDelivery) {
          name = (name == null) ? "仙南　" : (name + "仙南　");
        }
        if (value.senbokuDelivery) {
          name = (name == null) ? "仙北" : (name + "仙北");
        }
        value.ordersSalesOfficeName = name;
        return name;
      }
    },
    // 6か月以上動きのない在庫を強調表示
    getColor() {
      return function (lastDelivery) {
        let color = null;
        if (lastDelivery) {
          color = "white--text red darken-2";
        } else {
          color = "transparent";
        }
        return color;
      }
    },
    // supplierCodeの監視用
    supplierCode() {
      return this.currentParamsOrder.supplierCode;
    },
    // NETの計算
    calcNet:{
      get () {
        return 0;
      },
      set (resultData) {
        // NETの計算
        for (const item of this.orderDetailList) {
          // 最終仕入価格計算条件
          switch (resultData.SHIIRESAKI_REBATE_KBN) {
            case 1: // リベート区分が1の場合
              item.lastCost = parseFloat(item.net) * 0.94 * 1.0278;
              break;
            case 2: // リベート区分が2の場合
              item.lastCost = parseFloat(item.net) * 0.97 * 1.0278;
              break;
            default: // リベート区分が上記以外の場合
              // 手数料区分が1の場合はNET * 1.0278を、それでなければNETの値を最終仕入価格に格納
              item.lastCost = resultData.TESURYO_KBN !== 1 ? item.net : parseFloat(item.net) * 1.0278;
              break;
          }
          // 小数点以下2桁までの表示とする
          if (String(item.lastCost).indexOf(".") != -1) {
            item.lastCost = item.lastCost.toFixed(2);
          }
          // 数値に変換できない場合、最終仕入価格をnullとする
          item.lastCost = isNaN(item.lastCost) ? null : item.lastCost;
        }
      }
    },
    // 編集モーダル内でのNETの計算
    editedNet() {
      return function (value) {
        // 仕入先のコンボボックスが選択されていない場合はreturn
        const supplier = this.supplierList.find(
          (v) => v.code == this.currentParamsOrder.supplierCode
        );
        if (!supplier) {
          return;
        }
        // リベートフラグをfalseにする
        this.editedItem.rebateFlg = false;
        // 最終仕入価格計算条件
        switch (supplier.supplierRebateSection) {
          case 1: // リベート区分が1の場合
            this.editedItem.lastCost = parseFloat(value) * 0.94 * 1.0278;
            this.editedItem.rebateFlg = true;
            break;
          case 2: // リベート区分が2の場合
            this.editedItem.lastCost = parseFloat(value) * 0.97 * 1.0278;
            // 手数料区分が1以外の場合
            if (supplier.commissionSection !== 1) this.editedItem.rebateFlg = true;
            break;
          default: // リベート区分が上記以外の場合
            // 手数料区分が1の場合はNET * 1.0278を、それでなければNETの値を最終仕入価格に格納
            this.editedItem.lastCost = supplier.commissionSection !== 1 ? value : parseFloat(value) * 1.0278;
            break;
        }
        // 小数点以下2桁までの表示とする
        if (String(this.editedItem.lastCost).indexOf(".") != -1) {
          this.editedItem.lastCost = this.editedItem.lastCost.toFixed(2);
        }
        // 数値に変換できない場合、最終仕入価格をnullとする
        this.editedItem.lastCost = isNaN(this.editedItem.lastCost) ? null : this.editedItem.lastCost;
        return;
      }
    },
    // 返品値引処理済フラグの更新
    treatedOrder: {
      get() {
        let treatedOrder;
        for (const data of this.selected) {
          if (data.returnFlg === true || data.discountFlg === true) {
            treatedOrder = true;
            return treatedOrder;
          }
        }
        treatedOrder = false;
        return treatedOrder;
      },
      // ダイアログ明細No.設定
      formTitle() {
      return this.editOrderBranchNo;
    },
    },
  },
  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.resetValidate();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.isSavedData()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!(await this.validateCheck())) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディング画面の開始
        this.$store.commit("setLoading", true);
        // 空行を削除と商品コードのnullチェックを行う
        if (!(await this.deleteTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        if (this.orderDetailList.length === 0) {
          alert(ALERT_SAVE_ZERO_DATA);
          // 行を15行追加
          this.addLine(15);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 修正時の判定
        if (
          (await this.isRequestedUpdate()) ||
          typeof this.currentParamsOrder.orderNo == "number"
        ) {
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            // 画面遷移しない
            return;
          }
        } else {
          // 新規登録：入力データを登録
          if (!(await this.insertTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            // 画面遷移しない
            return;
          }
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      }
    }
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;
      this.loginUserInfo.BUMON_CD = 36;
      // データ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // ログイン中ユーザーの所属部門情報を取得
          this.departmentInfo = result.data.getM_SHOKISETTEI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 行追加用データを空の明細データで初期化
      this.editedItem = Object.assign({}, this.defaultItem);

      // 新規登録の場合
      // 部門名・発注番号用テキストボックスのデータ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.getM_SHOKISETTEI;
          this.currentParamsOrder.departmentCode = resultData.BUMON_CD;
          this.currentParamsOrder.departmentName = resultData.BUMON_NAME;
          this.currentParamsOrder.departmentOrderNo = resultData.HACCHU_NO;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 仕入先カナ用コンボボックスのリスト生成
      condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " ORDER BY SHIIRESAKI_CD",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_SHIIRESAKI;
          for (const data of resultData) {
            this.supplierList.push({
              comboItem: data.SHIIRESAKI_CD + "：" + data.SHIIRESAKI_KANA_NAME,
              code: data.SHIIRESAKI_CD,
              name: data.SHIIRESAKI_NAME,
              toshinSection: data.TOSHIN_KEIYU_KBN,
              panasonicSection: data.PANASONIC_KBN,
              commissionSection: data.TESURYO_KBN,
              supplierRebateSection: data.SHIIRESAKI_REBATE_KBN,
              purchaseOrderClass: data.HACCHUSHO_KBN,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 担当者用コンボボックスのリスト生成
      condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " ORDER BY TANTOSHA_CD",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_TANTOSHA, condition)
        );
        // 結果判定
        if (result.data.listM_TANTOSHA) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_TANTOSHA;
          for (const data of resultData) {
            this.managerList.push({
              comboItem: data.TANTOSHA_CD + "：" + data.TANTOSHA_NAME,
              code: data.TANTOSHA_CD,
              name: data.TANTOSHA_NAME,
              userCode: data.LOGIN_USER_CD,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      //  販売管理システムの営業所テーブルから営業所情報を取得。
      this.productClassList = [];
      let sqlClassificationList = [
        "SELECT EIGYOU.BU_EBUCD, EIGYOU.BU_EBUNM, EIGYOU.BU_KANA FROM EIGYOU INNER JOIN KANRI ON EIGYOU.BU_EBUCD = KANRI.SY_BASCD ORDER BY EIGYOU.BU_EBUCD",
      ];
      let resultSalesOfficeData = await this.executeHanbaikanriSql(sqlClassificationList);
      if (resultSalesOfficeData == null) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      for (const data of resultSalesOfficeData[0]) {
        this.salesOfficeNameList.push({
          comboItem: data.BU_EBUCD + "：" + data.BU_EBUNM,
          code: data.BU_EBUCD,
          name: data.BU_EBUNM,
        });
      }

      // 検索画面からの遷移の場合、更新値を取得
      this.reqOrderDataId = this.$route.query.reqOrderDataId
      if (this.isRequestedUpdate()) {
        // 既存データ修正の場合
        // 検索画面で指定された発注データ、発注明細データの取得
        let success = await this.getHacchuDatas(this.reqOrderDataId);
        if (!success) {
          return false;
        }
        // 登録時の担当者を表示
        let insertManager = this.managerList.find(
            (v) => v.userCode == this.currentParamsOrder.managerLoginUserCode
        );
        if (!insertManager) {
          this.managerList.push({
            comboItem: this.currentParamsOrder.managerCode + "：" + this.currentParamsOrder.managerName,
            code: this.currentParamsOrder.managerCode,
            name: this.currentParamsOrder.managerName,
            userCode: this.currentParamsOrder.managerLoginUserCode,
          });
        } else {
          this.currentParamsOrder.managerCode = insertManager.code;
          this.currentParamsOrder.managerName = insertManager.name;
          this.currentParamsOrder.managerLoginUserCode = insertManager.userCode;
        }
      } else {
        // ログイン中のユーザーで表示
        let initManager = this.managerList.find(
          (v) => v.userCode == this.loginUserInfo.LOGIN_USER_CD
        );
        if (initManager) {
          this.currentParamsOrder.managerCode = initManager.code;
          this.currentParamsOrder.managerName = initManager.name;
          this.currentParamsOrder.managerLoginUserCode = initManager.userCode;
        }
        // 新規登録の場合
        // 作成日付に当日を入力
        this.currentParamsOrder.orderDate = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        ).toISOString().substr(0, 10);
        
        // 初期表示で行を15行追加
        this.addLine(15);
      }
      // 入力前のデータを保存
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 担当者コードのコンボボックス更新時処理
     */
    updateManagerCombo() {
      // メソッド名を定義
      const method_name="updateManagerCombo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (this.currentParamsOrder.managerCode !== null) {
        // 担当者名を設定
        let selectedManager = this.managerList.find(
          (v) => v.code == this.currentParamsOrder.managerCode
        );
        this.currentParamsOrder.managerLoginUserCode = selectedManager.userCode;
        this.currentParamsOrder.managerName = selectedManager.name;
        this.currentParamsOrder.managerLoginUserCode = selectedManager.userCode;
      } else {
        this.currentParamsOrder.managerLoginUserCode = null;
        this.currentParamsOrder.managerName = null;
        this.currentParamsOrder.managerLoginUserCode = null;
      }

      // トシン経由区分が立っている場合、トシン系項目を設定
      this.updateToshinItems();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * トシン項目のテキストボックス更新処理
     */
    updateToshinItems() {
      // メソッド名を定義
      const method_name="updateToshinItems"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (
        this.currentParamsOrder.supplierCode == null ||
        this.currentParamsOrder.supplierCode == ""
      ) {
        console.log("updateToshinItems FALSE");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // トシン経由区分が立っている場合、トシン系項目を設定
      this.currentParamsOrder.toshinSection = this.supplierList.find(
        (v) => v.code == this.currentParamsOrder.supplierCode
      ).toshinSection;
      if (this.currentParamsOrder.toshinSection == 1) {
        if (this.currentParamsOrder.orderNo !== null) {
          // 発注番号－注文番号
          this.currentParamsOrder.toshinOrderNo =
              this.currentParamsOrder.departmentOrderNo + "－" + this.currentParamsOrder.orderNo;
          if (this.currentParamsOrder.orderNo !== null && this.currentParamsOrder.toshinOrderNo !== null) {
            // 担当者名[全角スペース]発注番号－注文番号
            this.currentParamsOrder.toshinManagerOrderNo =
                this.currentParamsOrder.managerCode + "　" + this.currentParamsOrder.toshinOrderNo;
          }
        }
      } else {
        this.currentParamsOrder.toshinClientNo = null;
        this.currentParamsOrder.toshinOrderNo = null;
        this.currentParamsOrder.toshinManagerOrderNo = null;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 既存データの更新チェック
     */
    isRequestedUpdate() {
      // メソッド名を定義
      const method_name="isRequestedUpdate"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return this.reqOrderDataId !== void 0;
    },
    /**
     * 未保存チェック用比較データの更新(発注データ、発注明細データ)
     */
    saveOriginalParams() {
      // メソッド名を定義
      const method_name="saveOriginalParams"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.saveOriginalParamsOrder();
      this.saveOriginalParamsDetails();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック用比較データの更新(発注データ)
     */
    saveOriginalParamsOrder() {
      // メソッド名を定義
      const method_name="saveOriginalParamsOrder"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParamsOrder = JSON.parse(
        JSON.stringify(this.currentParamsOrder)
      );
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック用比較データの更新(発注明細データ)
     */
    saveOriginalParamsDetails() {
      // メソッド名を定義
      const method_name="saveOriginalParamsDetails"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParamsDetails = JSON.parse(
        JSON.stringify(this.orderDetailList)
      );

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック(発注データ、発注明細データ)
     */
    isSavedData() {
      // メソッド名を定義
      const method_name="isSavedData"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (
        this.isSavedDataCurrentParamsOrder() &&
        this.isSavedDataCurrentParamsDetails()
      );
    },
    /**
     * 未保存チェック(発注データ)
     */
    isSavedDataCurrentParamsOrder() {
      // メソッド名を定義
      const method_name="isSavedDataCurrentParamsOrder"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (
        JSON.stringify(this.currentParamsOrder) ==
        JSON.stringify(this.originalParamsOrder)
      );
    },
    /**
     * 未保存チェック(発注明細データ)
     */
    isSavedDataCurrentParamsDetails() {
      // メソッド名を定義
      const method_name="isSavedDataCurrentParamsDetails"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let listLength = this.orderDetailList.length;
      if (this.originalParamsDetails.length > this.orderDetailList.length) {
        listLength = this.originalParamsDetails.length;
      }
      for (var i = 0; i <= listLength; i++) {
        if (
          JSON.stringify(this.originalParamsDetails[i]) !==
          JSON.stringify(this.orderDetailList[i])
        ) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return false;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 登録ボタン押下処理
     */
    async onClickRegister() {
      // メソッド名を定義
      const method_name="onClickRegister"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      console.log("onClickRegister [in]");
      // バリデーションチェックでエラーの際は登録しない
      if (!(await this.validateCheck())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 保存済みデータの場合、ダイアログを表示
      if (this.isSavedData()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      if (!confirm(CONFIRM_MESSAGE_SAVE_DATA)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 空行を削除と商品コードのnullチェックを行う
      if (!(await this.deleteTable())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      if (this.orderDetailList.length === 0) {
        alert(ALERT_SAVE_ZERO_DATA);
        // 行を15行追加
        this.addLine(15);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 修正ボタン押下もしくは連続登録しなかった場合
      if (
        this.isRequestedUpdate() ||
        typeof this.currentParamsOrder.orderNo == "number"
      ) {
        // 修正：入力データで更新
        if (!(await this.updateTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      } else {
        // 新規登録：入力データを登録
        if (!(await this.insertTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_INSERT_DATA);
        // バリデーションエラーをリセット
        this.resetValidate();
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      console.log("onClickRegister [out]");
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 新規入力ボタン押下処理
     */
    async onClickClearOrder() {
      // メソッド名を定義
      const method_name="onClickClearOrder"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存のデータがある場合はエラー
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 新しく「発注入力」画面を開く
      window.location.href = this.$route.path;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 新規登録処理
     */
    async insertTable() {
      // メソッド名を定義
      const method_name="insertTable"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      console.log("insertTable [in]");
      // 発注データテーブルへの登録
      // コード最大値の取得
      let registerOrderNo;
      let sqlCounterTables;
      // 検索条件 パナソニック区分またはその他で分ける
      let condition = {
        BUMON_CD: this.loginUserInfo.BUMON_CD,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getT_CHOHYO_COUNTER, condition)
        );
        console.log(
          "getT_CHOHYO_COUNTER：result = " +
            { result } +
            ", condition = " +
            JSON.stringify(condition)
        );

        // 結果判定
        if (result.data.getT_CHOHYO_COUNTER) {
          // データを取得できた場合
          // DBから取得した値を設定
          let resultData = result.data.getT_CHOHYO_COUNTER;
          // 通常用カウンタが最大値（99999）到達時は、1から採番
          registerOrderNo =
            resultData.HACCHUSHO_NO === 99999
              ? 1
              : resultData.HACCHUSHO_NO + 1;
          sqlCounterTables = `HACCHUSHO_NO = ${registerOrderNo},`;
          console.log(
            "getT_CHOHYO_COUNTER：registerOrderNo = " +
              registerOrderNo +
              ", sqlCounterTables = " +
              sqlCounterTables
          );
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            console.log(
              "getT_CHOHYO_COUNTER(error1)：result = " +
                JSON.stringify(result)
            );
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getT_CHOHYO_COUNTER(error2)： error = " + { error }
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getT_CHOHYO_COUNTER", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // 新規登録時のみ、注文番号取得のタイミングで設定
      if (this.currentParamsOrder.toshinSection == 1) {
        // 発注番号－注文番号
        this.currentParamsOrder.toshinOrderNo = this.currentParamsOrder.departmentOrderNo + "－" + registerOrderNo;
        // 担当者名[全角スペース]発注番号－注文番号
        this.currentParamsOrder.toshinManagerOrderNo = this.currentParamsOrder.managerCode + "　" + this.currentParamsOrder.toshinOrderNo;
      } else {
        this.currentParamsOrder.toshinClientNo = null;
        this.currentParamsOrder.toshinOrderNo = null;
        this.currentParamsOrder.toshinManagerOrderNo = null;
      }
      let toshinManagerOrderNo = null;
      let managerName = null;
      let description = null;
      let supplierName = null;
      let toshinOrderNo = null;
      let managerLoginUserCode = null;
      let userName = null;
      toshinManagerOrderNo = !this.currentParamsOrder.toshinManagerOrderNo ? null : `'${await this.deleteTabStr(this.currentParamsOrder.toshinManagerOrderNo)}'`;
      managerName = !this.currentParamsOrder.managerName ? null : `'${await this.deleteTabStr(this.currentParamsOrder.managerName)}'`;
      description = !this.currentParamsOrder.description ? null : `'${await this.deleteTabStr(this.currentParamsOrder.description)}'`;
      supplierName = !this.currentParamsOrder.supplierName ? null : `'${await this.deleteTabStr(this.currentParamsOrder.supplierName)}'`;
      toshinOrderNo = !this.currentParamsOrder.toshinOrderNo ? null : `'${this.currentParamsOrder.toshinOrderNo}'`;
      managerLoginUserCode = !this.currentParamsOrder.managerLoginUserCode ? null : `'${await this.deleteTabStr(this.currentParamsOrder.managerLoginUserCode)}'`;
      userName = !this.loginUserInfo.LOGIN_USER_CD ? null : `'${this.loginUserInfo.LOGIN_USER_CD}'`;
      let sqlList = [];
      // 発注データ用SQLを追加
      sqlList.push(
        `INSERT INTO T_CENTER_HACCHU_DATA (BUMON_CD, PANASONIC_KBN, CHUMON_NO, CHUMON_DATE, SHIIRESAKI_CD, SHIIRESAKI_NAME, TESURYO_KBN, TOSHIN_KEIYU_KBN, TOSHIN_OKYAKUSAMA_CD, TOSHIN_JUHACCHU_NO, TOSHIN_HACCHUSHA_CHUMON_NO, LOGIN_USER_CD, TANTOSHA_CD, TANTOSHA_NAME, TEKIYO, CREATE_DATE, CREATE_USER_NAME, UPDATE_DATE, UPDATE_USER_NAME) VALUES (` +
          `${this.loginUserInfo.BUMON_CD},` + //BUMON_CD
          `${this.currentParamsOrder.panasonicSection},` + //PANASONIC_KBN
          `${registerOrderNo},` + //CHUMON_NO
          `'${this.currentParamsOrder.orderDate}',` + //CHUMON_DATE
          `${this.currentParamsOrder.supplierCode},` + //SHIIRESAKI_CD
          `${supplierName},` + //SHIIRESAKI_NAME
          `${this.currentParamsOrder.commissionSection},` + //TESURYO_KBN
          `${this.currentParamsOrder.toshinSection},` + //TOSHIN_KEIYU_KBN
          `${this.currentParamsOrder.toshinClientNo},` + //TOSHIN_OKYAKUSAMA_CD
          `${toshinOrderNo},` + //TOSHIN_JUHACCHU_NO
          `${toshinManagerOrderNo},` + //TOSHIN_HACCHUSHA_CHUMON_NO
          `${managerLoginUserCode},` + //LOGIN_USER_CD
          `${this.currentParamsOrder.managerCode},` + //TANTOSHA_CD
          `${managerName},` + //TANTOSHA_NAME
          `${description},` + //TEKIYO
          "CURDATE()," + //CREATE_DATE
          `${userName},` + //CREATE_USER_NAME
          "CURDATE()," + //UPDATE_DATE
          `${userName});` //UPDATE_USER_NAME
      );

      let detailsDescription = "";
      let inDate = "";
      let salesOfficeName = "";
      let deliveryDate = null;
      let unit = "";
      let tempSlipPrintDate = null;
      let toshinSplitDate = null;
      let toshinSplitNo = null;
      let toshinSplitPrintDate = null;
      let purchaseAkaCsvOutputDate = null;
      let purchaseCsvOutputDate = null;
      let purchaseSlipNo = null;
      let productNo = null;
      let productJanCode = null;
      let productName = null;
      // 発注明細データ用SQLを追加
      for (const tableData of this.orderDetailList) {
        detailsDescription = !tableData.detailsDescription ? null : `'${await this.deleteTabStr(tableData.detailsDescription)}'`;
        salesOfficeName = !tableData.salesOfficeName ? null : `'${await this.deleteTabStr(tableData.salesOfficeName)}'`;
        inDate = !tableData.inDate ? null : `'${tableData.inDate}'`;
        deliveryDate = !tableData.deliveryDate ? null : `'${tableData.deliveryDate}'`;
        unit = !tableData.unit ? null : `'${await this.deleteTabStr(tableData.unit)}'`;
        tempSlipPrintDate = !tableData.tempSlipPrintDate ? null : `'${tableData.tempSlipPrintDate}'`;
        toshinSplitNo = !tableData.toshinSplitNo ? null : `'${await this.deleteTabStr(tableData.toshinSplitNo)}'`;
        toshinSplitDate = !tableData.toshinSplitDate ? null : `'${tableData.toshinSplitDate}'`;
        purchaseAkaCsvOutputDate = !tableData.purchaseAkaCsvOutputDate ? null : `'${tableData.purchaseAkaCsvOutputDate}'`;
        purchaseCsvOutputDate = !tableData.purchaseCsvOutputDate ? null : `'${tableData.purchaseCsvOutputDate}'`;
        purchaseSlipNo = !tableData.purchaseSlipNo ? null : `'${await this.deleteTabStr(tableData.purchaseSlipNo)}'`;
        productNo = !tableData.productNo ? null : `'${await this.deleteTabStr(tableData.productNo)}'`;
        productJanCode = !tableData.productJanCode ? null : `'${await this.deleteTabStr(tableData.productJanCode)}'`;
        productName = !tableData.productName ? null : `'${await this.deleteTabStr(tableData.productName)}'`;
        sqlList.push(
          `INSERT INTO T_CENTER_HACCHU_MEISAI_DATA VALUES (` +
            `LAST_INSERT_ID(),` + //HACCHU_DATA_ID
            `${tableData.orderBranchNo},` + //MEISAI_NO
            `${tableData.productCode},` + //SHOHIN_CD
            `${productNo},` + //HINBAN_NO
            `${productName},` + //SHOHIN_NAME
            `${tableData.amount},` + //SURYO_SU
            `${tableData.net},` + //NET_GK
            `${tableData.arrivalPurchasePrice},` + //SHIIRE_KAKAKU_GK
            `${tableData.lastCost},` + //SAISHU_SHIIRE_KAKAKU_GK
            `${salesOfficeName},` + //EIGYOU_NAME
            `${inDate},` + //CHAKUBI_DATE
            `${purchaseSlipNo},` + //SHIIRE_DENPYO_NO
            `${detailsDescription},` + //BIKO
            `${tableData.returnSlipNo},` + // HENDEN_NO
            `${Number(tableData.sendaiInFlg)},` + //SENDAI_NYUKA_FLG
            `${Number(tableData.tempSlipFlg)},` + //KARIDEN_FLG
            `${Number(tableData.slipFlg)},` + //HONDEN_FLG
            `${deliveryDate},` + //NOHINSHO_DATE
            `${unit},` + //TANI_NAME
            `${tableData.listPrice},` + //TEKA_GK
            `${Number(tableData.salesOfficeCode)},` + //EIGYOU_BUMON_CD
            `null,` + //GENBA_NAME
            `${Number(tableData.tempSlipPrintFlg)},` + //KARIDEN_INSATSU_FLG
            `${tempSlipPrintDate},` + //KARIDEN_INSATSU_DATE
            `${tableData.sendaiDelivery},` + //SENDAI_NOHINSU_SU
            `${tableData.sennanDelivery},` + //SENNAN_NOHINSU_SU
            `${tableData.senbokuDelivery},` + //SENBOKU_NOHINSU_SU
            `${Number(tableData.sennanInFlg)},` + //SENNAN_NYUKA_FLG
            `${Number(tableData.senbokuInFlg)},` + //SENBOKU_NYUKA_FLG
            `${productJanCode},` + //JAN_CD
            `${toshinSplitDate},` + //TOSHIN_DENPYO_DATE
            `${toshinSplitNo},` + //TOSHIN_DENPYO_NO
            `${Number(tableData.toshinSplitPrintFlg)},` + //TOSHIN_DENPYO_INSATSU_FLG
            `${toshinSplitPrintDate},` + //TOSHIN_DENPYO_INSATSU_DATE
            `${tableData.returnStatus},` + //HENPIN_STATUS_KBN
            `${tableData.splitSection},` + //DENPYO_KBN
            `${Number(tableData.pullUpFlg)},` + //HIKIAGE_FLG
            `${Number(tableData.returnRequestFlg)},` + //IRAI_FLG
            `${Number(tableData.returnProductFlg)},` + //HENKYAKU_FLG
            `${Number(tableData.purchaseAkaCsvOutputFlg)},` + //SHIIRE_AKA_FLG
            `${purchaseAkaCsvOutputDate},` + //SHIIRE_AKA_DATE
            `${tableData.purchaseCsvFlg},` + // SHIIRE_CSV_FLG
            `${purchaseCsvOutputDate},` + //SHIIRE_CSV_OUTPUT_DATE
            `${Number(tableData.salesOfficeCode)},` + //IDOSAKI_BUMON_CD
            `${tableData.cancelFlg},` + //CANCEL_FLG
            `CURDATE(),` + //CREATE_DATE
            `${userName},` + //CREATE_USER_NAME
            `CURDATE(),` + //UPDATE_DATE
            `${userName});` //UPDATE_USER_NAME
        );
      }

      // カウンタテーブルの値を更新
      sqlList.push(
        `UPDATE T_CHOHYO_COUNTER SET ` +
          sqlCounterTables +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = ${userName}`+
          ` WHERE BUMON_CD = '${this.loginUserInfo.BUMON_CD}';`
      );

      condition = { SQLs: sqlList };
      console.log("insertTable : condition = " + JSON.stringify(condition));

      let registeredDataId = -1;
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );

        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            // レスポンスメッセージ
            // let message = responseBody.message;
            // // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            console.info("insertTable：エラー" + responseBody);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              console.log(
                "responseBody.data[0][0] = " +
                  JSON.stringify(responseBody.data[0][0])
              );
              registeredDataId = responseBody.data[0][0]["LAST_INSERT_ID()"];
              console.log("registeredDataId = " + registeredDataId);
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("insertTable：エラー2 : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でステータスエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("insertTable : 異常終了 = " + { error });
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      console.log("insertTable : updating... ");

      // 新規登録した発注データ・発注明細データの取得
      let success = await this.getHacchuDatas(registeredDataId);
      if (!success) {
        return false;
      }
      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // バリデーションエラーをリセット
      this.resetValidate();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 修正処理
     */
    async updateTable() {
      // メソッド名を定義
      const method_name="updateTable"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      console.log("updateTable [in]");
      let sqlList = [];
      let toshinManagerOrderNo = null;
      let managerName = null;
      let description = null;
      let supplierName = null;
      let toshinOrderNo = null;
      let managerLoginUserCode = null;
      let userName = null;
      toshinManagerOrderNo = !this.currentParamsOrder.toshinManagerOrderNo ? null : `'${await this.deleteTabStr(this.currentParamsOrder.toshinManagerOrderNo)}'`;
      managerName = !this.currentParamsOrder.managerName ? null : `'${await this.deleteTabStr(this.currentParamsOrder.managerName)}'`;
      description = !this.currentParamsOrder.description ? null : `'${await this.deleteTabStr(this.currentParamsOrder.description)}'`;
      supplierName = !this.currentParamsOrder.supplierName ? null : `'${await this.deleteTabStr(this.currentParamsOrder.supplierName)}'`;
      toshinOrderNo = !this.currentParamsOrder.toshinOrderNo ? null : `'${this.currentParamsOrder.toshinOrderNo}'`;
      managerLoginUserCode = !this.currentParamsOrder.managerLoginUserCode ? null : `'${await this.deleteTabStr(this.currentParamsOrder.managerLoginUserCode)}'`;
      userName = !this.loginUserInfo.LOGIN_USER_CD ? null : `'${this.loginUserInfo.LOGIN_USER_CD}'`;
      // 発注データ用SQLを追加
      sqlList.push(
        `UPDATE T_CENTER_HACCHU_DATA SET` +
          ` PANASONIC_KBN = ${this.currentParamsOrder.panasonicSection},` +
          ` CHUMON_DATE = '${this.currentParamsOrder.orderDate}',` +
          ` SHIIRESAKI_CD = ${this.currentParamsOrder.supplierCode},` +
          ` SHIIRESAKI_NAME = ${supplierName},` +
          ` TESURYO_KBN = ${this.currentParamsOrder.commissionSection},` +
          ` TOSHIN_KEIYU_KBN = ${this.currentParamsOrder.toshinSection},` +
          ` TOSHIN_OKYAKUSAMA_CD = ${this.currentParamsOrder.toshinClientNo},` +
          ` TOSHIN_JUHACCHU_NO = ${toshinOrderNo},` +
          ` TOSHIN_HACCHUSHA_CHUMON_NO = ${toshinManagerOrderNo},` +
          ` LOGIN_USER_CD = ${managerLoginUserCode},` +
          ` TANTOSHA_CD = ${this.currentParamsOrder.managerCode},` +
          ` TANTOSHA_NAME = ${managerName},` +
          ` TEKIYO = ${description},` +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = ${userName}` +
          ` WHERE HACCHU_DATA_ID = ${this.currentParamsOrder.orderDataId};`
      );

      // 発注明細データを全消し
      sqlList.push(
        `DELETE FROM T_CENTER_HACCHU_MEISAI_DATA` +
          ` WHERE HACCHU_DATA_ID = ${this.currentParamsOrder.orderDataId};`
      );

      let detailsDescription = "";
      let inDate = "";
      let salesOfficeName = "";
      let deliveryDate = null;
      let unit = "";
      let tempSlipPrintDate = null;
      let toshinSplitNo = null;
      let toshinSplitDate = null;
      let toshinSplitPrintDate = null;
      let purchaseAkaCsvOutputDate = null;
      let purchaseCsvOutputDate = null;
      let purchaseSlipNo = "";
      let productNo = null;
      let productJanCode = null;
      let productName = null;
      // 発注明細データ用SQLを追加
      for (const tableData of this.orderDetailList) {
        detailsDescription = !tableData.detailsDescription ? null : `'${await this.deleteTabStr(tableData.detailsDescription)}'`;
        salesOfficeName = !tableData.salesOfficeName ? null : `'${await this.deleteTabStr(tableData.salesOfficeName)}'`;
        inDate = !tableData.inDate ? null : `'${tableData.inDate}'`;
        deliveryDate = !tableData.deliveryDate ? null : `'${tableData.deliveryDate}'`;
        unit = !tableData.unit ? null : `'${await this.deleteTabStr(tableData.unit)}'`;
        tempSlipPrintDate = !tableData.tempSlipPrintDate ? null : `'${tableData.tempSlipPrintDate}'`;
        toshinSplitNo = !tableData.toshinSplitNo ? null : `'${await this.deleteTabStr(tableData.toshinSplitNo)}'`;
        toshinSplitDate = !tableData.toshinSplitDate ? null : `'${tableData.toshinSplitDate}'`;
        toshinSplitPrintDate = !tableData.toshinSplitPrintDate ? null : `'${tableData.toshinSplitPrintDate}'`;
        purchaseAkaCsvOutputDate = !tableData.purchaseAkaCsvOutputDate ? null : `'${tableData.purchaseAkaCsvOutputDate}'`;
        purchaseCsvOutputDate = !tableData.purchaseCsvOutputDate ? null : `'${tableData.purchaseCsvOutputDate}'`;
        purchaseSlipNo = !tableData.purchaseSlipNo ? null : `'${await this.deleteTabStr(tableData.purchaseSlipNo)}'`;
        productNo = !tableData.productNo ? null : `'${await this.deleteTabStr(tableData.productNo)}'`;
        productJanCode = !tableData.productJanCode ? null : `'${await this.deleteTabStr(tableData.productJanCode)}'`;
        productName = !tableData.productName ? null : `'${await this.deleteTabStr(tableData.productName)}'`;
        sqlList.push(
          `INSERT INTO T_CENTER_HACCHU_MEISAI_DATA VALUES (` +
            `${this.currentParamsOrder.orderDataId},` + //HACCHU_DATA_ID
            `${tableData.orderBranchNo},` + //MEISAI_NO
            `${tableData.productCode},` + //SHOHIN_CD
            `${productNo},` + //HINBAN_NO
            `${productName},` + //SHOHIN_NAME
            `${tableData.amount},` + //SURYO_SU
            `${tableData.net},` + //NET_GK
            `${tableData.arrivalPurchasePrice},` + //SHIIRE_KAKAKU_GK
            `${tableData.lastCost},` + //SAISHU_SHIIRE_KAKAKU_GK
            `${salesOfficeName},` + //EIGYOU_NAME
            `${inDate},` + //CHAKUBI_DATE
            `${purchaseSlipNo},` + //SHIIRE_DENPYO_NO
            `${detailsDescription},` + //BIKO
            `${tableData.returnSlipNo},` + // HENDEN_NO
            `${Number(tableData.sendaiInFlg)},` + //SENDAI_NYUKA_FLG
            `${Number(tableData.tempSlipFlg)},` + //KARIDEN_FLG
            `${Number(tableData.slipFlg)},` + //HONDEN_FLG
            `${deliveryDate},` + //NOHINSHO_DATE
            `${unit},` + //TANI_NAME
            `${tableData.listPrice},` + //TEKA_GK
            `${Number(tableData.salesOfficeCode)},` + //EIGYOU_BUMON_CD
            `${null},` + //GENBA_NAME
            `${Number(tableData.tempSlipPrintFlg)},` + //KARIDEN_INSATSU_FLG
            `${tempSlipPrintDate},` + //KARIDEN_INSATSU_DATE
            `${tableData.sendaiDelivery},` + //SENDAI_NOHINSU_SU
            `${tableData.sennanDelivery},` + //SENNAN_NOHINSU_SU
            `${tableData.senbokuDelivery},` + //SENBOKU_NOHINSU_SU
            `${Number(tableData.sennanInFlg)},` + //SENNAN_NYUKA_FLG
            `${Number(tableData.senbokuInFlg)},` + //SENBOKU_NYUKA_FLG
            `${productJanCode},` + //JAN_CD
            `${toshinSplitDate},` + //TOSHIN_DENPYO_DATE
            `${toshinSplitNo},` + //TOSHIN_DENPYO_NO
            `${Number(tableData.toshinSplitPrintFlg)},` + //TOSHIN_DENPYO_INSATSU_FLG
            `${toshinSplitPrintDate},` + //TOSHIN_DENPYO_INSATSU_DATE
            `${tableData.returnStatus},` + //HENPIN_STATUS_KBN
            `${tableData.splitSection},` + //DENPYO_KBN
            `${Number(tableData.pullUpFlg)},` + //HIKIAGE_FLG
            `${Number(tableData.returnRequestFlg)},` + //IRAI_FLG
            `${Number(tableData.returnProductFlg)},` + //HENKYAKU_FLG
            `${Number(tableData.purchaseAkaCsvOutputFlg)},` + //SHIIRE_AKA_FLG
            `${purchaseAkaCsvOutputDate},` + //SHIIRE_AKA_DATE
            `${tableData.purchaseCsvFlg},` + // SHIIRE_CSV_FLG
            `${purchaseCsvOutputDate},` + //SHIIRE_CSV_OUTPUT_DATE
            `${Number(tableData.salesOfficeCode)},` + //IDOSAKI_BUMON_CD
            `${tableData.cancelFlg},` + //CANCEL_FLG
            `CURDATE(),` + //CREATE_DATE
            `${userName},` + //CREATE_USER_NAME
            `CURDATE(),` + //UPDATE_DATE
            `${userName});` //UPDATE_USER_NAME
        );
      }

      let condition = { SQLs: sqlList };
      console.log("updateTables：condition = " + JSON.stringify(condition));
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            // console.info({responseBody});
            // // レスポンスメッセージ
            // let message = responseBody.message;
            // console.error({message});
            // // エラー内容
            // const errorMessage = responseBody.error;
            // console.error({errorMessage});
            console.log("updateTables：error : " + JSON.stringify(result));
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("updateTables : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でステータスエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("updateTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 更新した発注データ・発注明細データの取得
      let success = await this.getHacchuDatas(
        this.currentParamsOrder.orderDataId
      );
      if (!success) {
        return false;
      }
      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // バリデーションエラーをリセット
      this.resetValidate();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * tab文字の削除
     */
    async deleteTabStr(csvDataStr) {
      return String(csvDataStr).replace(/\t+/g, " ");
    },
    /**
     * バリデーションチェック
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 発注データ、発注明細データのバリデーション
      return this.validateCheckOrder() && this.validateCheckDetails();
    },
    /**
     * バリデーションチェック(発注データ)
     */
    validateCheckOrder() {
      // メソッド名を定義
      const method_name="validateCheckOrder"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 発注データのバリデーション
      let form = this.$refs.formOrder;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * バリデーションチェック(行追加時の発注明細データ)
     */
    validateCheckModal() {
      // メソッド名を定義
      const method_name="validateCheckModal"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 発注明細データのバリデーション
      let form = this.$refs.formModal;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * バリデーションチェック(明細行の発注明細データ)
     */
    validateCheckDetails() {
      // メソッド名を定義
      const method_name="validateCheckDetails"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 発注明細データのバリデーション
      let form = this.$refs.formDetails;
      let returnFlg = true;
      if (!form.validate()) {
        // エラー情報を取得
        let result = form.inputs.find((input) => input.hasError);
        console.dir(result);
        for (const header of this.headers) {
          if (header.value == result.id) {
            // ユーザーの操作がない（初期値と入力後で値が変わらない）場合はcontinue
            if (result.initialValue === null && result.lazyValue === null) {
              continue;
            }
            alert(header.text + "の値が不正です。\n" + result.value);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            returnFlg = false;
          }
        }
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return returnFlg;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return returnFlg;
    },
    /**
     * 不要な行の削除処理
     */
    deleteTable() {
      // メソッド名を定義
      const method_name="deleteTable"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 空欄行以外を検索する
      let notNUllList = [];
      for (const data of this.orderDetailList) {
          // 明細No.、受注営業所の場合は処理を飛ばす
        for (const key in this.defaultItem) {
          if (key == "orderBranchNo" || key == "ordersSalesOfficeName") {
            continue;
          }
          // 空行でない明細Noリストを生成
          if (this.defaultItem[key] !== data[key]) {
            notNUllList.push(data.orderBranchNo);
            continue;
          }
        }
      }
      // 明細Noの重複を削除
      notNUllList = [...new Set(notNUllList)];
      // データテーブル行数とnotNUllListが一致していれば処理を抜ける
      if (this.orderDetailList.length !== notNUllList.length) {
        // 空欄行を削除する
        let result = this.orderDetailList.filter((a) => {
          if (notNUllList.indexOf(a.orderBranchNo) !== -1) {
            return a;
          }
        });
        // 明細No順にソートする
        result.sort((a, b) => a.orderBranchNo - b.orderBranchNo);
        // 明細Noの振り直しを行う
        for (var i = 0; i < result.length; i++) {
          // 商品コードまたは営業所が空の場合、処理を抜ける
          if (!result[i].productCode) {
            alert(
              "明細No." + result[i].orderBranchNo + "の商品コードが空欄です"
            );
            return false;
          } else if (result[i].salesOfficeCode == null) {
            alert(
              "明細No." + result[i].orderBranchNo + "の営業所名が空欄です"
            );
            return false;
          }
          result[i].orderBranchNo = i + 1;
        }
        this.orderDetailList = result;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 住所入力ボタン押下処理
     */
    async onClickInputAddress() {
      // メソッド名を定義
      const method_name="onClickInputAddress"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 販売管理システム 営業所基本情報テーブルから住所情報を取得
      if (this.orderDetailList.length === 0 || this.orderDetailList[0].salesOfficeCode == null) {
        alert("明細で営業所を入力してください。");
        return;
      }
      // ローディング画面の表示
      this.$store.commit("setLoading", true);
      let sqlList = [
        "SELECT EIGYOU.BU_EBUCD, EIGYOU.BU_EBUNM, KANRI.SY_ADDNO, KANRI.SY_ADDNM1, KANRI.SY_TELNO1 FROM EIGYOU INNER JOIN KANRI ON EIGYOU.BU_EBUCD = KANRI.SY_BASCD" +
          " WHERE KANRI.SY_BASCD = " +
          this.orderDetailList[0].salesOfficeCode,
      ];
      let condition = { SQLs: sqlList };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        console.log(
          "KASWAKATSU：onClickInputAddress：condition = " +
            JSON.stringify(condition)
        );
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          // console.log(result);
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            const message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return;
          } else {
            // SQLが正常に終了した際の処理
            let resultData = responseBody.data[0][0];
            this.currentParamsOrder.description = resultData.BU_EBUNM;
            this.currentParamsOrder.description += `\n〒${resultData.SY_ADDNO ? resultData.SY_ADDNO : ""}`;
            this.currentParamsOrder.description += `  ${resultData.SY_ADDNM1 ? resultData.SY_ADDNM1 : ""}`;
            this.currentParamsOrder.description += `\nTEL：${resultData.SY_TELNO1 ? resultData.SY_TELNO1 : ""}`;
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          this.$store.commit("setLoading", false);
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return;
        }
      } catch (error) {
        console.error(error);
        this.$store.commit("setLoading", false);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return;
      }
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * PDF出力ボタン押下処理
     */
    async onClickPdfPrint() {
      // メソッド名を定義
      const method_name="onClickPdfPrint"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.isSavedData() || !this.currentParamsOrder.orderDataId) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!(await this.validateCheck())) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // 空行を削除と商品コードのnullチェックを行う
          if (!(await this.deleteTable())) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // 明細行のデータ存在チェック 
          if (this.orderDetailList.length === 0) {
            alert(ALERT_SAVE_ZERO_DATA);
            // 行を15行追加
            this.addLine(15);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          
          // ローディング画面の開始
          this.$store.commit("setLoading", true);

          // 修正時の判定
          if (
            (await this.isRequestedUpdate()) ||
            typeof this.currentParamsOrder.orderNo == "number"
          ) {
            // 修正：入力データで更新
            if (!(await this.updateTable())) {
              // ローディングを解除
              this.$store.commit("setLoading", false);
              // エラーダイアログを表示
              alert(ALERT_MESSAGE_COMMON_ERROR);
              // ログ出力(method-end)
              addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
              // 画面遷移しない
              return;
            }
          } else {
            // 新規登録：入力データを登録
            if (!(await this.insertTable())) {
              // ローディングを解除
              this.$store.commit("setLoading", false);
              // エラーダイアログを表示
              alert(ALERT_MESSAGE_COMMON_ERROR);
              // ログ出力(method-end)
              addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
              // 画面遷移しない
              return;
            }
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      } else {
        console.log("●印刷処理");
        console.log(this.copyFormat.value);
        switch (this.copyFormat.value.toString()) {
          case '3': {
            console.log('発注書（東北商品センター用）');
            // 発注書（東北商品センター用）PDF出力処理
            let route = this.$router.resolve({ name: 'CenterOrder', params: { hacchu_data_id: this.currentParamsOrder.orderDataId }});
            window.open(route.href, '_blank');
            break;
          }
          case '4': {
            console.log('トシン受発注票');
            // トシン受発注票PDF出力処理
            // パラメータをBase64エンコード
            const options = {
              hacchu_data_id: this.currentParamsOrder.orderDataId,
              isCenter: true,
            };
            const encoded = await base64Encode(JSON.stringify(options));
            let route = this.$router.resolve({ name: 'ToshinOrder', params: { params: encoded }});
            window.open(route.href, '_blank');
            break;
          }
          case '6': {
            console.log('返品依頼書');
            // 対象レコード存在チェック
            const whereOptions = `AND HACCHU_DATA_ID = ${this.currentParamsOrder.orderDataId} AND DENPYO_KBN = 2`;
            try {
              let result = await API.graphql(graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI, {where_options: whereOptions}));
              let orderData = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
              if(orderData && orderData.length > 0){
                // 返品依頼書PDF出力処理
                // パラメータをBase64エンコード
                const options = {
                  hacchu_data_id: this.currentParamsOrder.orderDataId,
                  isCenter: true,
                };
                const encoded = await base64Encode(JSON.stringify(options));
                let route = this.$router.resolve({ name: 'ReturnRequest', params: { params: encoded }});
                window.open(route.href, '_blank');
              } else {
                this.dialogDataNotExists = true;
              }
            } catch (error) {
              console.log(error);
            }
            break;
          }
          case '7': {
            console.log('返品伝票');
            // 対象レコード存在チェック
            const whereOptions = `AND HACCHU_DATA_ID = ${this.currentParamsOrder.orderDataId} AND DENPYO_KBN = 2`;
            try {
              let result = await API.graphql(graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI, {where_options: whereOptions}));
              let orderData = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
              if(orderData && orderData.length > 0){
                // 返品依頼書PDF出力処理
                // パラメータをBase64エンコード
                const options = {
                  hacchu_data_id: this.currentParamsOrder.orderDataId,
                  isCenter: true,
                };
                const encoded = await base64Encode(JSON.stringify(options));
                let route = this.$router.resolve({ name: 'ReturnSlip', params: { params: encoded }});
                window.open(route.href, '_blank');
              } else {
                this.dialogDataNotExists = true;
              }
            } catch (error) {
              console.log(error);
            }
            break;
          }
          default:
            break;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ボタン押下時の処理
     */
    onClickEditItem(item) {
      // メソッド名を定義
      const method_name="onClickEditItem"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // editItemを呼び出す
      this.editItem(item);
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ボタン
     */
    editItem(item) {
      // メソッド名を定義
      const method_name="editItem"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // モーダルに表示する明細Noの設定
      this.editOrderBranchNo = item.orderBranchNo;
      this.editedIndex = this.orderDetailList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // 品番のコンボボックスに初期値を設定（既に入力しているデータには入力データ、新規レコードの場合はNULLが設定される）
      this.openProductNo(item);
      // バリデーションエラーをリセット
      this.$refs.formModal.resetValidation();
      this.dialog = true;
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    /**
     * 削除ボタン押下時処理
     */
    async deleteItem(item) {
      // メソッド名を定義
      const method_name="deleteItem"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.editedIndex = this.orderDetailList.indexOf(item);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      if (confirm(CONFIRM_MESSAGE_DELETE_DATA)) {
        this.orderDetailList.splice(this.editedIndex, 1);
        this.renumbering(Number(item.orderBranchNo), "delete");
        this.closeDelete();
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    // 追加編集ダイアログのクローズ処理
    close() {
      // メソッド名を定義
      const method_name="close"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.editedAmount = -1;
      // バリデーションエラーをリセット
      this.$refs.formModal.resetValidation();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    /**
     *  削除ダイアログのクローズ処理
     */
    async closeDelete() {
      // メソッド名を定義
      const method_name="closeDelete"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      // チェックがあれば解除
      this.selected = [];
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ダイアログのOKボタン
     */
    async saveClose() {
      // メソッド名を定義
      const method_name="saveClose"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は追加しない
      if (!this.validateCheckModal()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 在庫数を更新
      if (!(await this.getStock(this.editedItem))) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      this.$refs.unit.blur();
      this.$nextTick(() => {
        // 編集インデックスが-1以上の時、行数をリセットする。
        if (this.editedIndex > -1) {
          Object.assign(
            this.orderDetailList[this.editedIndex],
            this.editedItem
          );
        }
      });
      // モーダルを閉じる
      this.close();
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ダイアログのOK（続けて入力）ボタン
     */
    async saveAddLine() {
      // メソッド名を定義
      const method_name="saveAddLine"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は追加しない
      if (!this.validateCheckModal()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 在庫数を更新
      if (!(await this.getStock(this.editedItem))) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      this.$refs.unit.blur();
      this.$nextTick(() => {
        // 編集インデックスが-1以上の時、行数をリセットする。
        if (this.editedIndex > -1) {
          Object.assign(
            this.orderDetailList[this.editedIndex],
            this.editedItem
          );
        }
        // editedItemを初期化
        this.editedItem = Object.assign({}, this.defaultItem);
        // 明細最終行の場合、新規レコードを挿入し、連続入力を行う
        if (!this.orderDetailList[this.editedIndex + 1]) {
          this.editedItem.orderBranchNo = this.orderDetailList.length + 1;
          this.orderDetailList.push(this.editedItem);
        }
          this.editItem(this.orderDetailList[this.editedIndex + 1])
      });
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 追加ダイアログのOKボタン
     */
    addLine(loop) {
      // メソッド名を定義
      const method_name="addLine"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 行追加を行う。
      // チェックされている場合、選択行の一行上に行追加を行う。
      if (this.selected[0]) {
        // 配列を初期化する。
        this.editedItem = Object.assign({}, this.defaultItem);
        this.renumbering(Number(this.selected[0].orderBranchNo));
        this.editedItem.orderBranchNo =
          Number(this.selected[0].orderBranchNo) - 1;
        this.orderDetailList.push(this.editedItem);
        // 選択を解除する
        this.selected = [];
        this.$nextTick(() => {
          // 明細No順にソートする
          this.orderDetailList.sort((a, b) => a.orderBranchNo - b.orderBranchNo);
        });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 最終行に行追加を行う。
      for (var j = 0; j < loop; j++) {
        // 配列を初期化する。
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.orderBranchNo = this.orderDetailList.length + 1;
        this.orderDetailList.push(this.editedItem);
      }
      // 選択を解除する
      this.selected = [];
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  返品処理ダイアログのクローズ処理
     */
    closeReturn() {
      // メソッド名を定義
      const method_name="closeReturn"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.dialogReturn = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.selected = [];
        // 明細No順にソートする
        this.orderDetailList.sort((a, b) => a.orderBranchNo - b.orderBranchNo);
      });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  返品処理ダイアログのOKボタン
     */
    async saveReturn() {
      // メソッド名を定義
      const method_name="saveReturn"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      for (const row of this.selected) {
        if (row.productNo == null) {
          alert("明細No." + row.orderBranchNo + "の品番が空欄です");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        } 
        if (row.salesOfficeCode == null){
          alert("明細No." + row.orderBranchNo + "の営業所名が空欄です");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        if (row.amount == 0 || row.amount == null) {
          alert("明細No." + row.orderBranchNo + "の数量が0又は空欄です");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        } 
        await this.renumbering(Number(row.orderBranchNo) + 1);
        const copyRow = Object.assign({}, row);

        copyRow.orderBranchNo = Number(row.orderBranchNo) + 1;
        this.orderDetailList.push(copyRow);
        if (this.returnOrDiscount == 0) {
          copyRow.returnFlg = true;
          // 伝票区分を2(返品)
          copyRow.splitSection = 2;
          copyRow.amount = -copyRow.amount;
        } else {
          copyRow.discountFlg = true;
          // 伝票区分を3(値引)
          copyRow.splitSection = 3;
          copyRow.amount = -copyRow.amount;
        }
        // 発注入力の画面で更新されるデータ以外は初期値に戻す
        copyRow.inDate = null;
        copyRow.returnSlipNo = null;
        copyRow.listPrice = null;
        copyRow.tempSlipPrintFlg = false;
        copyRow.tempSlipPrintDate = null;
        copyRow.productJanCode = null;
        copyRow.purchaseSlipNo = null;
        copyRow.toshinSplitDate = null;
        copyRow.toshinSplitNo = null;
        copyRow.toshinSplitPrintFlg = false;
        copyRow.toshinSplitPrintDate = null;
        copyRow.purchaseCsvFlg = false;
        copyRow.purchaseCsvOutputDate = null;
        copyRow.cancelFlg = false;
        copyRow.pullUpFlg = false;
        copyRow.returnRequestFlg = false;
        copyRow.returnProductFlg = false;
        copyRow.purchaseAkaCsvOutputFlg = false;
        copyRow.purchaseAkaCsvOutputDate = null;
        copyRow.destinationDepartmentCode = null;
        copyRow.deliveryDate = null;
        copyRow.createdDate = null;
        copyRow.createdUser = null;
        copyRow.updatedDate = null;
        copyRow.updatedUser = null;
      }
      this.closeReturn();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  データテーブルの編集ダイアログ OKボタン
     */
    saveDetails() {
      // メソッド名を定義
      const method_name="saveDetails"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     *  データテーブルの単位編集ダイアログ open
     */
    openUnit(item) {
      // メソッド名を定義
      const method_name="openUnit"
      // オペレーションタイプを定義
      const operation_type="0004"
      this.viewUnit = item.unit;
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     *  データテーブルの単位編集ダイアログ OKボタン
     */
    saveUnit(item) {
      // メソッド名を定義
      const method_name="saveUnit"
      // オペレーションタイプを定義
      const operation_type="0004"
      this.$refs.unitComboBox.blur();
      this.$nextTick(() => {
        item.unit = this.viewUnit;
      });
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     *  データテーブルの編集ダイアログ キャンセルボタン
     */
    cancel() {
       // メソッド名を定義
      const method_name="cancel"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     *  レコード未選択時の処理
     */
    checkItem() {
      // メソッド名を定義
      const method_name="checkItem"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      alert(ALERT_UNSELECTED_DATA);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  返品値引がある場合スタイルを変更処理
     */
    rowClasses(item) {
      // メソッド名を定義
      const method_name="rowClasses"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (item.returnFlg == true) {
        return "style-1";
      } else if (item.discountFlg == true) {
        return "style-2";
      }
    },
    /**
     *  返品値引済の場合アラートを出す。
     */
    treatedItem() {
      // メソッド名を定義
      const method_name="treatedItem"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      for (const row of this.selected) {
        if (row.returnFlg == true || row.discountFlg == true) {
          alert("返品、または値引の明細が選択されています。");
          return;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  マスタ検索からの返り値を元に、マスタ情報を検索する
     */
    response(item) {
      // メソッド名を定義
      const method_name="response"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      if (item) {
        let returnList = this.orderDetailList.find(
          (items) =>items.orderBranchNo === item.returnNo);
        // どのマスタ画面からの戻り値か判定
        // 行追加より検索した場合
        if (this.dialog) {
          this.editedItem.productCode = item.returnProductCode;
          this.getProductList(this.editedItem);
        // 明細データより検索した場合
        } else {
          returnList.productCode = item.returnProductCode;
          this.getProductList(returnList);
        }
      }
      this.dialogProductSearch = false;
      this.dialogProductInsert = false;
      this.insertProductNo = null;
      this.targetBranchNo = 0;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * beforeUpdateNet呼び出し時の処理
     */
    async callUpdateNet() {
      // メソッド名を定義
      const method_name="callUpdateNet"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // beforeUpdateNetを呼ぶ
      if (!(await this.beforeUpdateNet())) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      return;
    },
    /**
     * calcNet呼び出し時の処理
     */
    async beforeUpdateNet() {
      // メソッド名を定義
      const method_name="beforeUpdateNet"
      // オペレーションタイプを定義
      const operation_type="0004"
      // 仕入先が未選択ならば処理を抜ける
      if (!this.currentParamsOrder.supplierCode) {
        return true;
      }
      // 検索条件
      let condition = {
        BUMON_CD : this.loginUserInfo.BUMON_CD,
        SHIIRESAKI_CD: this.currentParamsOrder.supplierCode
      };
      let resultData;
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHIIRESAKI, condition)
        );

        // 結果判定
        if (result.data.getM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          resultData = result.data.getM_SHIIRESAKI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
           if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log("異常系ログ:" + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 算出プロパティを呼び出す
      this.calcNet = resultData;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 明細Noの振り直し
     */
    renumbering(orderBranchNo, flg) {
      // メソッド名を定義
      const method_name="renumbering"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      for (const row of this.orderDetailList) {
        switch (flg) {
          case "delete":
            if (Number(row.orderBranchNo) >= orderBranchNo) {
              row.orderBranchNo--;
            }
            break;
          default:
            if (Number(row.orderBranchNo) >= orderBranchNo) {
              row.orderBranchNo++;
            }
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 品番入力時の初期値設定処理
     */
    async openProductNo(item) {
      // メソッド名を定義
      const method_name="openProductNo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 品番入力用のコンボボックス表示時に表示する初期値を設定
      this.productObj = item.productNo;
      if (this.productObj && typeof this.productObj !== 'object') {
        this.productObj = {
          comboItem: item.productCode + "：" + item.productNo,
          code: item.productCode,
          productNo: item.productNo,
        };
      }

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 品番選択時の自動入力
     */
    async changeProductNoList(item, productObj) {
      // メソッド名を定義
      const method_name="changeProductNoList"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 品番未選択の場合はreturn
      if (typeof productObj !== 'object' || !productObj) {
        productObj = null;
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      const searchProduct = this.productList.find(
        (items) => items.code === productObj.code
      );
      // 検索した商品がリストにいない場合、何もしない
      if (!searchProduct) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      if (!this.dialog) {
        item.productNo = searchProduct.productNo;
        item.productName = searchProduct.name;
        item.productCode = searchProduct.code;
        item.arrivalPurchasePrice = searchProduct.arrivalPurchasePrice;
        item.net = searchProduct.arrivalPurchasePrice;
      } else {
        this.editedItem.productNo = searchProduct.productNo;
        this.editedItem.productName = searchProduct.name;
        this.editedItem.productCode = searchProduct.code;
        this.editedItem.arrivalPurchasePrice = searchProduct.arrivalPurchasePrice;
        this.editedItem.net = searchProduct.arrivalPurchasePrice;
      }
      // 在庫数を更新
      if (!(await this.getStock(item))) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // NET入力時計算
      await this.callUpdateNet();
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 商品検索画面からの返り値の取得
     */
    async getProductList(item) {
      // メソッド名を定義
      const method_name="getProductList"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 商品コードに紐づくデータを取得
      let resultSerchData;
      let sqlSerchList = [
        "SELECT * FROM SHOHIN  WHERE SH_SHOCD =" + item.productCode,
      ];
      let condition = { SQLs: sqlSerchList };
      // 商品コードに紐づくデータを取得
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            const message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          } else {
            // SQLが正常に終了した際の処理
            console.log(
              "responseBody.data" + JSON.stringify(responseBody.data)
            );
            resultSerchData = responseBody.data[0];
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.error("error" + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // 結果判定
      for (const data of resultSerchData) {
        if (item.orderBranchNo !== "") {
          item.productNo = data.SH_SHOKNM;
          item.productName = data.SH_SHONM;
          item.productCode = data.SH_SHOCD;
          item.arrivalPurchasePrice = data.SH_SIRTNA;
          item.net = data.SH_SIRTNA;
        }
        this.productList.push({
          comboItem: data.SH_SHOCD + "：" + data.SH_SHOKNM,
          code: data.SH_SHOCD,
          productNo: data.SH_SHOKNM,
          name: data.SH_SHONM,
          arrivalPurchasePrice: data.SH_SIRTNA,
          net: data.SH_SIRTNA,
        });
      }
      // 品番に値を設定
      this.productObj = item.productNo;
      if (this.productObj && typeof this.productObj !== 'object') {
        this.productObj = {
          comboItem: item.productCode + "：" + item.productNo,
          code: item.productCode,
          productNo: item.productNo,
        };
      }
      // 在庫数を更新
      if (!(await this.getStock(item))) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // NET入力時計算
      await this.callUpdateNet();
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 営業所名選択時の自動入力
     */
    async changeSalesOfficeNameList(item) {
      // メソッド名を定義
      const method_name="changeCssClientNameList"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 営業所名未選択の場合はreturn
      if (!item.salesOfficeName) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 選択した営業所名のデータを取得
      const searchClient = this.salesOfficeNameList.find(
        (items) => items.name === item.salesOfficeName
      );
      // リストに存在しない場合、何もしない
      if (!searchClient) {
        // ローディング画面の開始
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      if (item.orderBranchNo !== "") {
        item.salesOfficeCode = searchClient.code;
        item.salesOfficeName = searchClient.name;
      } else {
        this.editedItem.salesOfficeCode = searchClient.code;
        this.editedItem.salesOfficeName = searchClient.name;
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 在庫数の取得
     */
    async getStock(editItem) {
      // メソッド名を定義
      const method_name="getStock"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 営業所別在庫数の取得
      let resultStockData;
      // 検索画面からの遷移の場合
      if(!editItem.productCode){
        editItem.productCode = editItem.SHOHIN_CD
      }
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      let sqlList = [
        "SELECT BAZAI.BZ_BASCD, BAZAI.BZ_ENDOTDT, BAZAI.BZ_SHOCD, BAZAI.BZ_TMZANSU, BAZAI.BZ_TMINSU, BAZAI.BZ_TMOTSU, BAZAI.BZ_TMMINSU,BAZAI.BZ_TMMOTSU, BAZAI.BZ_YMINSU, BAZAI.BZ_YMOTSU, BAZAI.BZ_YMMINSU, BAZAI.BZ_YMMOTSU FROM BAZAI " +
          "WHERE BAZAI.BZ_SHOCD = " +
          editItem.productCode +
          " AND BAZAI.BZ_BASCD IN (30,31,32)",
      ];
      let condition = { SQLs: sqlList };
      console.info({ condition });
      try {
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            const message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            console.log(responseBody.data);
            resultStockData = responseBody.data[0];
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.error(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // 在庫数の初期化
      editItem.sennanAmount = null;
      editItem.sendaiAmount = null;
      editItem.senbokuAmount = null;
      // データが取得できなかった場合、最終出庫日をfalse、在庫数を0にする。
      if (resultStockData.length < 1) {
        editItem.sennanLastDate = false;
        editItem.sendaiLastDate = false;
        editItem.senbokuLastDate = false;
        editItem.sennanAmount = 0;
        editItem.sendaiAmount = 0;
        editItem.senbokuAmount = 0;
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return true;
      }
      let sixMonthBeforeDate = new Date();
      sixMonthBeforeDate.setMonth(sixMonthBeforeDate.getMonth()-6)
      for (const office of resultStockData) {
        var deliveryDate = office.BZ_ENDOTDT;
        let calcDate;
        // 6ヵ月以上在庫に動きがなければtrue、あればfalse
        deliveryDate = String(deliveryDate);
        let compareDate = (deliveryDate.substr(0, 4) + "/" + deliveryDate.substr(4, 2) + "/" + deliveryDate.substr(6, 2));
        compareDate = new Date(compareDate);
         // 日数の差分を算出
        let termDay = (sixMonthBeforeDate - compareDate) / 86400000;
        calcDate = termDay >= 0 ? true : false
        switch (office.BZ_BASCD) {
          case 30:
            editItem.sennanAmount =
              office.BZ_TMZANSU +
              office.BZ_TMINSU -
              office.BZ_TMOTSU +
              office.BZ_TMMINSU -
              office.BZ_TMMOTSU +
              office.BZ_YMINSU -
              office.BZ_YMOTSU +
              office.BZ_YMMINSU -
              office.BZ_YMMOTSU;
            editItem.sennanLastDate = calcDate;
            break;
          case 31:
            editItem.sendaiAmount =
              office.BZ_TMZANSU +
              office.BZ_TMINSU -
              office.BZ_TMOTSU +
              office.BZ_TMMINSU -
              office.BZ_TMMOTSU +
              office.BZ_YMINSU -
              office.BZ_YMOTSU +
              office.BZ_YMMINSU -
              office.BZ_YMMOTSU;
            editItem.sendaiLastDate = calcDate;
            break;
          case 32:
            editItem.senbokuAmount =
              office.BZ_TMZANSU +
              office.BZ_TMINSU -
              office.BZ_TMOTSU +
              office.BZ_TMMINSU -
              office.BZ_TMMOTSU +
              office.BZ_YMINSU -
              office.BZ_YMOTSU +
              office.BZ_YMMINSU -
              office.BZ_YMMOTSU;
            editItem.senbokuLastDate = calcDate;
            break;
          default:
            break;
        }
        // 在庫数がnullの場合、0を代入
        editItem.sennanAmount = !editItem.sennanAmount ? 0 : editItem.sennanAmount;
        editItem.sendaiAmount = !editItem.sendaiAmount ? 0 : editItem.sendaiAmount;
        editItem.senbokuAmount = !editItem.senbokuAmount ? 0 : editItem.senbokuAmount;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 発注データ・発注明細データの取得処理
     * ・検索画面からの遷移時
     * ・登録・修正後の画面データ更新
     */
    async getHacchuDatas(orderDataId) {
      // メソッド名を定義
      const method_name="getHacchuDatas"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      console.log(
        "getHacchuDatas [in] : orderDataId = " + orderDataId
      );
      // 発注データの取得
      let condition = {
        HACCHU_DATA_ID: orderDataId,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getT_CENTER_HACCHU_DATA, condition)
        );

        // 結果判定
        if (result.data.getT_CENTER_HACCHU_DATA) {
          // データを取得できた場合
          // DBから取得した値を設定
          let resultData = result.data.getT_CENTER_HACCHU_DATA;
          this.currentParamsOrder.orderDataId = resultData.HACCHU_DATA_ID;
          this.currentParamsOrder.departmentCode = resultData.BUMON_CD;
          this.currentParamsOrder.panasonicSection = resultData.PANASONIC_KBN;
          this.currentParamsOrder.orderNo = resultData.CHUMON_NO;
          this.currentParamsOrder.orderDate = resultData.CHUMON_DATE;
          this.currentParamsOrder.supplierCode = resultData.SHIIRESAKI_CD;
          this.currentParamsOrder.supplierName = resultData.SHIIRESAKI_NAME;
          this.currentParamsOrder.commissionSection = resultData.TESURYO_KBN;
          this.currentParamsOrder.toshinSection = resultData.TOSHIN_KEIYU_KBN;
          this.currentParamsOrder.toshinClientNo = resultData.TOSHIN_OKYAKUSAMA_CD;
          this.currentParamsOrder.toshinOrderNo = resultData.TOSHIN_JUHACCHU_NO;
          this.currentParamsOrder.toshinManagerOrderNo = resultData.TOSHIN_HACCHUSHA_CHUMON_NO;
          this.currentParamsOrder.managerCode = resultData.TANTOSHA_CD;
          this.currentParamsOrder.managerName = resultData.TANTOSHA_NAME;
          this.currentParamsOrder.description = resultData.TEKIYO;
          this.currentParamsOrder.createdDate = resultData.CREATE_DATE;
          this.currentParamsOrder.createdUser = resultData.CREATE_USER_NAME;
          this.currentParamsOrder.updatedDate = resultData.UPDATE_DATE;
          this.currentParamsOrder.updatedUser = resultData.UPDATE_USER_NAME;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "getT_CENTER_HACCHU_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // 業務ログ出力
            console.log(
              "getHacchuDatas(getT_CENTER_HACCHU_DATA)：error 1 = " +
                JSON.stringify(result)
            );
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getT_CENTER_HACCHU_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getT_CENTER_HACCHU_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getHacchuDatas(getT_CENTER_HACCHU_DATA)：error 2 = " + { error }
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getT_CENTER_HACCHU_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 発注明細データの取得
      condition = {
        where_options:
          "AND HACCHU_DATA_ID = " + orderDataId + " ORDER BY MEISAI_NO ASC",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listT_CENTER_HACCHU_MEISAI_DATA, condition)
        );

        // 結果判定
        if (result.data.listT_CENTER_HACCHU_MEISAI_DATA) {
          // データを取得できた場合、DBから取得した値を設定
          let resultData = result.data.listT_CENTER_HACCHU_MEISAI_DATA;
          console.log(
            "getHacchuDatas(listT_CENTER_HACCHU_MEISAI_DATA) ：resultData = " +
              JSON.stringify(resultData)
          );
          // 検索画面からの遷移時
          if (this.orderDetailList.length === 0) {
            for (const data of resultData) {
              // 在庫数の更新
              if (!(await this.getStock(data))) {
                // ログ出力(method-end)
                addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                return false;
              }
              this.orderDetailList.push({
                orderBranchNo: data.MEISAI_NO,
                productCode: data.SHOHIN_CD,
                productNo: data.HINBAN_NO,
                productName: data.SHOHIN_NAME,
                amount: data.SURYO_SU,
                net: data.NET_GK,
                arrivalPurchasePrice: data.SHIIRE_KAKAKU_GK,
                lastCost: data.SAISHU_SHIIRE_KAKAKU_GK,
                salesOfficeName: data.EIGYOU_NAME,
                inDate: data.CHAKUBI_DATE,
                purchaseSlipNo: data.SHIIRE_DENPYO_NO,
                detailsDescription: data.BIKO,
                returnSlipNo: data.HENDEN_NO,
                sendaiInFlg: Boolean(data.SENDAI_NYUKA_FLG),
                tempSlipFlg: Boolean(data.KARIDEN_FLG),
                slipFlg: Boolean(data.HONDEN_FLG),
                deliveryDate: data.NOHINSHO_DATE,
                unit: data.TANI_NAME,
                listPrice: data.TEKA_GK,
                salesOfficeCode: data.EIGYOU_BUMON_CD,
                tempSlipPrintFlg: data.KARIDEN_INSATSU_FLG,
                tempSlipPrintDate: data.KARIDEN_INSATSU_DATE,
                sendaiDelivery: data.SENDAI_NOHINSU_SU,
                sennanDelivery: data.SENNAN_NOHINSU_SU,
                senbokuDelivery: data.SENBOKU_NOHINSU_SU,
                sennanInFlg: Boolean(data.SENNAN_NYUKA_FLG),
                senbokuInFlg: Boolean(data.SENBOKU_NYUKA_FLG),
                productJanCode: data.JAN_CD,
                toshinSplitDate: data.TOSHIN_DENPYO_DATE,
                toshinSplitNo: data.TOSHIN_DENPYO_NO,
                toshinSplitPrintFlg: Boolean(data.TOSHIN_DENPYO_INSATSU_FLG),
                toshinSplitPrintDate: data.TOSHIN_DENPYO_INSATSU_DATE,
                returnStatus: data.HENPIN_STATUS_KBN,
                splitSection: data.DENPYO_KBN,
                returnFlg: data.DENPYO_KBN == 2,
                pullUpFlg: Boolean(data.HIKIAGE_FLG),
                returnRequestFlg: Boolean(data.IRAI_FLG),
                returnProductFlg: Boolean(data.HENKYAKU_FLG),
                discountFlg: data.DENPYO_KBN == 3,
                purchaseAkaCsvOutputFlg: Boolean(data.SHIIRE_AKA_FLG),
                purchaseAkaCsvOutputDate: data.SHIIRE_AKA_DATE,
                purchaseCsvFlg: Boolean(data.SHIIRE_CSV_FLG),
                purchaseCsvOutputDate: data.SHIIRE_CSV_OUTPUT_DATE,
                destinationDepartmentCode: data.IDOSAKI_BUMON_CD,
                cancelFlg: data.CANCEL_FLG,
                createdDate: data.CREATE_DATE,
                createdUser: data.CREATE_USER_NAME,
                updatedDate: data.UPDATE_DATE,
                updatedUser: data.UPDATE_USER_NAME,
                sennanAmount: data.sennanAmount,
                sendaiAmount: data.sendaiAmount,
                senbokuAmount: data.senbokuAmount,
                sennanLastDate: data.sennanLastDate,
                sendaiLastDate: data.sendaiLastDate,
                senbokuLastDate: data.senbokuLastDate,
              });
            }
          } else {
            for (let i = 0; i < resultData.length; i++) {
              this.orderDetailList[i].orderDataId =
                resultData[i].HACCHU_DATA_ID;
              this.orderDetailList[i].orderBranchNo = resultData[i].MEISAI_NO,
              this.orderDetailList[i].productCode = resultData[i].SHOHIN_CD,
              this.orderDetailList[i].productNo = resultData[i].HINBAN_NO,
              this.orderDetailList[i].productName = resultData[i].SHOHIN_NAME,
              this.orderDetailList[i].amount = resultData[i].SURYO_SU,
              this.orderDetailList[i].net = resultData[i].NET_GK,
              this.orderDetailList[i].arrivalPurchasePrice = resultData[i].SHIIRE_KAKAKU_GK,
              this.orderDetailList[i].lastCost = resultData[i].SAISHU_SHIIRE_KAKAKU_GK,
              this.orderDetailList[i].salesOfficeName = resultData[i].EIGYOU_NAME,
              this.orderDetailList[i].outDate = resultData[i].SHUKKA_DATE,
              this.orderDetailList[i].inDate = resultData[i].CHAKUBI_DATE,
              this.orderDetailList[i].purchaseSlipNo = resultData[i].SHIIRE_DENPYO_NO,
              this.orderDetailList[i].detailsDescription = resultData[i].BIKO,
              this.orderDetailList[i].returnSlipNo = resultData[i].HENDEN_NO,
              this.orderDetailList[i].wholesaleCost = resultData[i].OROSHI_KAKAKU_GENKA_GK,
              this.orderDetailList[i].sendaiInFlg = Boolean(resultData[i].SENDAI_NYUKA_FLG),
              this.orderDetailList[i].tempSlipFlg = Boolean(resultData[i].KARIDEN_FLG),
              this.orderDetailList[i].slipFlg = Boolean(resultData[i].HONDEN_FLG),
              this.orderDetailList[i].deliveryDate = resultData[i].NOHINSHO_DATE,
              this.orderDetailList[i].unit = resultData[i].TANI_NAME,
              this.orderDetailList[i].listPrice = resultData[i].TEKA_GK,
              this.orderDetailList[i].salesOfficeCode = resultData[i].EIGYOU_BUMON_CD,
              this.orderDetailList[i].tempSlipPrintFlg = Boolean(resultData[i].KARIDEN_INSATSU_FLG),
              this.orderDetailList[i].tempSlipPrintDate = resultData[i].KARIDEN_INSATSU_DATE,
              this.orderDetailList[i].sendaiDelivery = resultData[i].SENDAI_NOHINSU_SU,
              this.orderDetailList[i].sennanDelivery = resultData[i].SENNAN_NOHINSU_SU,
              this.orderDetailList[i].senbokuDelivery = resultData[i].SENBOKU_NOHINSU_SU,
              this.orderDetailList[i].sennanInFlg = Boolean(resultData[i].SENNAN_NYUKA_FLG),
              this.orderDetailList[i].senbokuInFlg = Boolean(resultData[i].SENBOKU_NYUKA_FLG),
              this.orderDetailList[i].productJanCode = resultData[i].JAN_CD,
              this.orderDetailList[i].toshinSplitDate = resultData[i].TOSHIN_DENPYO_DATE,
              this.orderDetailList[i].toshinSplitNo = resultData[i].TOSHIN_DENPYO_NO;
              this.orderDetailList[i].toshinSplitPrintFlg = Boolean(resultData[i].TOSHIN_DENPYO_INSATSU_FLG),
              this.orderDetailList[i].toshinSplitPrintDate = resultData[i].TOSHIN_DENPYO_INSATSU_DATE,
              this.orderDetailList[i].returnStatus = resultData[i].HENPIN_STATUS_KBN,
              this.orderDetailList[i].splitSection = resultData[i].DENPYO_KBN,
              this.orderDetailList[i].returnFlg = resultData[i].DENPYO_KBN  == 2,
              this.orderDetailList[i].pullUpFlg = Boolean(resultData[i].HIKIAGE_FLG),
              this.orderDetailList[i].returnRequestFlg = Boolean(resultData[i].IRAI_FLG),
              this.orderDetailList[i].returnProductFlg = Boolean(resultData[i].HENKYAKU_FLG),
              this.orderDetailList[i].discountFlg = resultData[i].DENPYO_KBN  == 3,
              this.orderDetailList[i].purchaseAkaCsvOutputFlg = Boolean(resultData[i].SHIIRE_AKA_FLG),
              this.orderDetailList[i].purchaseAkaCsvOutputDate = resultData[i].SHIIRE_AKA_DATE,
              this.orderDetailList[i].purchaseCsvFlg = Boolean(resultData[i].SHIIRE_CSV_FLG),
              this.orderDetailList[i].purchaseCsvOutputDate = resultData[i].SHIIRE_CSV_OUTPUT_DATE,
              this.orderDetailList[i].destinationDepartmentCode = resultData[i].IDOSAKI_BUMON_CD,
              this.orderDetailList[i].cancelFlg = resultData[i].CANCEL_FLG,
              this.orderDetailList[i].createdDate = resultData[i].CREATE_DATE,
              this.orderDetailList[i].createdUser = resultData[i].CREATE_USER_NAME,
              this.orderDetailList[i].updatedDate = resultData[i].UPDATE_DATE,
              this.orderDetailList[i].updatedUser = resultData[i].UPDATE_USER_NAME
            }
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listT_CENTER_HACCHU_MEISAI_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          console.log(
            "getHacchuDatas(listT_CENTER_HACCHU_MEISAI_DATA)：error 1 = " +
              JSON.stringify(result)
          );
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listT_CENTER_HACCHU_MEISAI_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getHacchuDatas(listT_CENTER_HACCHU_MEISAI_DATA)：error 2 = " +
            JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listT_CENTER_HACCHU_MEISAI_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      console.log("getHacchuDatas [out]");
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 納品数入力時の営業所自動入力処理
     */
    updateSalesOfficeCombo(item, updateDepartmentCode) {
      // メソッド名を定義
      const method_name="updateSalesOfficeCombo"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 入力された営業所以外の納品数を確認
      let othersDelivery = 0;
      switch(updateDepartmentCode) {
        case DEPARTMENT_SENDAI:
          othersDelivery = item.sennanDelivery + item.senbokuDelivery;
          break;
        case DEPARTMENT_SENNAN:
          othersDelivery = item.sendaiDelivery + item.senbokuDelivery;
          break;
        case DEPARTMENT_SENBOKU:
          othersDelivery = item.sennanDelivery + item.sendaiDelivery;
          break;
        default:
          break;
      }

      // 営業所コード、営業所名の自動入力が必要な場合
      if (othersDelivery === 0) {
        let selectedOffice = this.salesOfficeNameList.find(
          (item) => item.code === updateDepartmentCode
        );
        // リストに存在する営業所だった場合
        if (selectedOffice) {
          if (!this.dialog) {
            // v-data-table上の納品数を入力した場合
            item.salesOfficeCode = selectedOffice.code;
            item.salesOfficeName = selectedOffice.name;
          } else {
            // 編集ダイアログ上の納品数を入力した場合
            this.editedItem.salesOfficeCode = selectedOffice.code;
            this.editedItem.salesOfficeName = selectedOffice.name;
          }
        }
      }

      this.totalAmount(item);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 数量の自動入力処理
     */
    totalAmount(item) {
      // メソッド名を定義
      const method_name="totalAmount"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      item.amount = item.sendaiDelivery + item.sennanDelivery + item.senbokuDelivery;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * バリデーションチェックをリセット
     */
    resetValidate() {
      // メソッド名を定義
      const method_name="resetValidate"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ヘッダーのバリデーションをリセット
      this.$refs.formOrder.resetValidation();
      // 明細行のバリデーションをリセット
      if (this.$refs.formDetails) {
        this.$refs.formDetails.resetValidation();
      }
      // モーダルのバリデーションをリセット
      if (this.$refs.formModal) {
        this.$refs.formModal.resetValidation();
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 販売管理システム用関数の実行処理
     */
    async executeHanbaikanriSql(sqlList) {
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let condition = { SQLs: sqlList };
      console.log(
        "executeHanbaikanriSql：condition = " + JSON.stringify(condition)
      );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            console.log("executeHanbaikanriSql：error");
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return null;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            console.log("executeHanbaikanriSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return responseBody.data;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeHanbaikanriSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        console.log(
          "executeHanbaikanriSql : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
    /**
     * 発注データ・発注明細データの削除
     */
    async submitDeleteOrder() {
      // メソッド名を定義
      const method_name="submitDeleteOrder"

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 削除バリデーション
      const errorMessage = this.deleteValidateCheck()
      // エラーメッセージが含まれる場合終了
      if(errorMessage) {
        // エラーダイアログを表示
        alert(errorMessage);
        this.$store.commit("setLoading", false);
        return;
      }
      // 発注データ削除確認ダイアログ
      if(!confirm(CONFIRM_MESSAGE_DELETE_ORDER)) {
        // ローディング終了
        this.$store.commit("setLoading", false); 
        return;
      }

      let sqlList = [];

      // 発注明細データ削除SQL
      sqlList.push(`DELETE FROM T_CENTER_HACCHU_MEISAI_DATA WHERE HACCHU_DATA_ID = ${this.currentParamsOrder.orderDataId}`);

      // 発注データ削除SQL
      sqlList.push(`DELETE FROM T_CENTER_HACCHU_DATA WHERE HACCHU_DATA_ID = ${this.currentParamsOrder.orderDataId}`);

      // トランザクションSQL実行
      const condition = { SQLs: sqlList };
      let errorFlg = false;
      try {
        const result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );

        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            // エラー内容ログ出力
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], {message: responseBody?.error});
            errorFlg = true;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でステータスエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          errorFlg = true;
        }
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        errorFlg = true;
      }

      if(errorFlg) {
        // エラーが起きたら共通エラーメッセージ
        alert(ALERT_MESSAGE_COMMON_ERROR)
        // ローディング終了
        this.$store.commit("setLoading", false); 
      } else {
        // エラーがなければ完了メッセージ
        alert(ALERT_COMPLETE_DELETE_ORDER)
        // 発注検索画面に遷移
        this.$router.push("/center_order_search");
      }
    },
    /**
     * 発注データ削除リクエスト時バリデーション
     */
    deleteValidateCheck() {
      for (const item of this.orderDetailList) {
        const ALERTS = ALERT_DELETE_NOT_ALLOWED;
        // 仕入CSV出力済の場合NG
        if(item.purchaseCsvFlg) return ALERTS.SHIIRE_CSV_FLG;

        // 仮伝印刷済の場合NG
        if(item.tempSlipPrintFlg) return ALERTS.KARIDEN_INSATSU_FLG;

        // 返品依頼済の場合NG
        if(item.returnRequestFlg) return ALERTS.IRAI_FLG;

        // 返品済の場合NG
        if(item.returnProductFlg) return ALERTS.HENKYAKU_FLG;

        // トシン伝票印刷済の場合NG
        if(item.toshinSplitPrintFlg) return ALERTS.TOSHIN_DENPYO_INSATSU_FLG;
      }
    }
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.v-data-table >>> th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}
.v-data-table >>> td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
/* 2列目スクロール時固定 */
.v-data-table >>> th:nth-child(2) {
  position: sticky;
  left: 50px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.v-data-table >>> td:nth-child(2) {
  position: sticky;
  left: 50px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
/* 3列目スクロール時固定 */
.v-data-table >>> th:nth-child(3) {
  position: sticky;
  left: 130px; /* 前列までの列幅指定 */
  z-index: 2;
  background-color: white;
}
.v-data-table >>> td:nth-child(3) {
  position: sticky;
  left: 130px; /* 前列までの列幅指定 */
  z-index: 1;
  background-color: white;
}
.style-1 td {
  background-color: rgb(235, 108, 57) !important;
}
.style-2 td {
  background-color: rgb(255, 255, 0) !important;
}
.theme--light.v-label {
  font-size: small;
}
.row + .row {
  margin-top: 0;
}
.toshin-no-padding-margin {
  padding: 0;
  margin: 0;
}
.productNoIconLayout {
  padding-left: 0;
  padding-bottom: 20px;
}
.v-application .mt-3 {
  margin-top: 0 !important;
}
</style>
